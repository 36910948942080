import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";


import EmojiEvents from '@material-ui/icons/EmojiEvents';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import TodayIcon from '@material-ui/icons/Today';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Warning from "components/Typography/Warning.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
// import { FormatAlignJustify, EmojiEvents } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function CampSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Közelgő Eseményeink</h2>
          {/* <h5 className={classes.description}>
            Leendő táboraink...
          </h5> */}
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer justify="center">
          {/*<GridItem xs={12} sm={12} md={4}>
             <InfoArea
              title="FCI Terelőverseny - Herding Trial IHT-TS-1-2, NHAT, HWT"
              description="Székesfehérvár - Március 12"
              icon={EmojiEvents}
              iconColor="warning"
              vertical
            />
            <a href="https://www.facebook.com/events/1101604237322576/" target="_blank">További információk facebook-on</a>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Technikai továbbképző WORKSHOP (TS kutyáknak)"
              description="Székesfehérvár - Március 20"
              icon={SchoolIcon}
              iconColor="success"
              vertical
            />
            <a href="https://www.facebook.com/events/2831633473796797/" target="_blank">További információk facebook-on</a>
          </GridItem> */}
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="TS Continental Trial"
              description="Székesfehérvár - Április 14 - 17"
              information="További információk a TS CONTINENTAL TRIAL 2022 fül alatt, vagy"
              icon={EmojiEvents}
              iconColor="info"
              vertical
            />
            <a href="https://www.facebook.com/subacontinental/" target="_blank">a facebook-on</a>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
