import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import Timeline from "@material-ui/icons/Timeline";

import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";
import ListAlt from "@material-ui/icons/ListAlt";
import LabelImportant from "@material-ui/icons/LabelImportant";
import Event from "@material-ui/icons/Event";
import EventAvailable from "@material-ui/icons/EventAvailable";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Today from "@material-ui/icons/Today";

import EmojiEvents from '@material-ui/icons/EmojiEvents';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import Stars from '@material-ui/icons/Stars';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import InfoArea from "components/InfoArea/InfoArea.js";
import Warning from "components/Typography/Warning.js";

import Badge from 'components/Badge/Badge.js';

import subaProfile from "assets/img/suba_white_gray.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

    return (
        <div>
            <Header
                color="transparent"
                brand="Suhanó Bárányok"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/barik.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.profile}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div>
                                            <img src={subaProfile} alt="..." className={imageClasses} />
                                        </div>
                                        <div className={classes.name}>
                                            <h2>Eseményeink 2022-re</h2>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.description}>
                            {/* <p align="justify">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porta commodo magna, ac
                                accumsan tortor lobortis eu. Integer mollis, tellus vitae feugiat semper, enim ipsum
                                malesuada nibh, nec laoreet odio erat at ligula. Cras vehicula mauris lacinia risus
                                vulputate porta in quis augue. Donec sit amet pulvinar turpis. Sed sagittis.
                            </p>
                            <p align="justify">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec porta commodo magna, ac
                                accumsan tortor lobortis eu. Integer mollis, tellus vitae feugiat semper, enim ipsum
                                malesuada nibh, nec laoreet odio erat at ligula. Cras vehicula mauris lacinia risus
                                vulputate porta in quis augue. Donec sit amet pulvinar turpis. Sed sagittis.
                            </p> */}
                            <p align="justify">
                                Kutyád már rendelkezik ösztönpróba vizsgával de nem tudod hogyan tovább? Gyere el táborunkba, ahol segítünk felkészülni a munkavizsgára (FCI-HWT = Herding Working Test) és a versenyfeladatokra.
                                Ha szeretnéd elsajátítani az alapvető feladatokat és technikákat, ha szeretnéd azt érezni hogy ura vagy a helyzetnek egy birkanyáj mellett, ha szeretnéd hogy kutyáddal sikeresen tudjatok együttműködni, akkor itt a helyed!
                            </p>
                        </div>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                                <NavPills
                                    alignCenter
                                    color="primary"
                                    tabs={[
                                        {
                                            tabButton: "Összes",
                                            tabIcon: ListAlt,
                                            tabContent: (
                                                <GridContainer justify="center">
                                                    {/* <GridItem xs={12} sm={12} md={4}>
                                                        <InfoArea
                                                            title="FCI Terelőverseny - Herding Trial IHT-TS-1-2, NHAT, HWT"
                                                            description="Székesfehérvár - Március 12"
                                                            icon={EmojiEvents}
                                                            iconColor="warning"
                                                            vertical
                                                        />
                                                        <a href="https://www.facebook.com/events/1101604237322576/" target="_blank">További információk facebook-on</a>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <InfoArea
                                                            title="Technikai továbbképző WORKSHOP (TS kutyáknak)"
                                                            description="Székesfehérvár - Március 20"
                                                            icon={SchoolIcon}
                                                            iconColor="success"
                                                            vertical
                                                        />
                                                        <a href="https://www.facebook.com/events/2831633473796797/" target="_blank">További információk facebook-on</a>
                                                    </GridItem> */}
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <InfoArea
                                                            title="TS Continental Trial"
                                                            description="Székesfehérvár - Április 14 - 17"
                                                            information="További információk a TS CONTINENTAL TRIAL 2022 fül alatt, vagy"
                                                            icon={EmojiEvents}
                                                            iconColor="info"
                                                            vertical
                                                        />
                                                        <a href="https://www.facebook.com/subacontinental/" target="_blank">a facebook-on</a>
                                                    </GridItem>
                                                </GridContainer>
                                            )
                                        },
                                        {
                                            tabButton: "Versenyek",
                                            tabIcon: EmojiEvents,
                                            tabContent: (
                                                <GridContainer justify="center">
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <InfoArea
                                                            title="FCI Terelőverseny - Herding Trial IHT-TS-1-2, NHAT, HWT"
                                                            description="Székesfehérvár - Március 12"
                                                            icon={EmojiEvents}
                                                            iconColor="warning"
                                                            vertical
                                                        />
                                                        <a href="https://www.facebook.com/events/1101604237322576/" target="_blank">További információk facebook-on</a>
                                                        <InfoArea
                                                            title="TS Continental Trial"
                                                            description="Székesfehérvár - Április 14 - 17"
                                                            information="További információk a TS CONTINENTAL TRIAL 2022 fül alatt, vagy"
                                                            icon={EmojiEvents}
                                                            iconColor="info"
                                                            vertical
                                                        />
                                                        <a href="https://www.facebook.com/subacontinental/" target="_blank">a facebook-on</a>
                                                    </GridItem>
                                                </GridContainer>
                                            )
                                        },
                                        {
                                            tabButton: "Táborok",
                                            tabIcon: SchoolIcon,
                                            tabContent: (
                                                <GridContainer justify="center">
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <InfoArea
                                                            title="Technikai továbbképző WORKSHOP (TS kutyáknak)"
                                                            description="Székesfehérvár - Március 20"
                                                            icon={SchoolIcon}
                                                            iconColor="success"
                                                            vertical
                                                        />
                                                        <a href="https://www.facebook.com/events/2831633473796797/" target="_blank">További információk facebook-on</a>
                                                    </GridItem>
                                                </GridContainer>
                                            )
                                        }
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
