import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import ProfilePageBea from "views/ProfilePage/ProfilePageBea.js";
import SuBaRegulations from "views/About/SuBaRegulations/SuBaRegulations.js";
import Program from "views/About/Program/Program.js";
import LoginPage from "views/LoginPage/LoginPage.js";

import ContinentalProgram from "views/About/Program/Continental/2022/ContinentalProgram.js";
import ContinentalHouseRules from "views/About/Program/Continental/2022/ContinentalHouseRules.js";
import ContinentalInformation from "views/About/Program/Continental/2022/ContinentalInformation.js";
import ContinentalSponsors from "views/About/Program/Continental/2022/ContinentalSponsors.js";
import ContinentalJudges from "views/About/Program/Continental/2022/ContinentalJudges.js";
import ContinentalParticipants from "views/About/Program/Continental/2022/ContinentalParticipants.js";
import ContinentalResults from "views/About/Program/Continental/2022/ContinentalResults.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page-bea" component={ProfilePageBea} />
      <Route path="/suba-regulations" component={SuBaRegulations} />
      <Route path="/program" component={Program} />
      <Route path="/continental-program" component={ContinentalProgram} />
      <Route path="/continental-house-rules" component={ContinentalHouseRules} />
      <Route path="/continental-information" component={ContinentalInformation} />
      <Route path="/continental-sponsors" component={ContinentalSponsors} />
      <Route path="/continental-judges" component={ContinentalJudges} />
      <Route path="/continental-participants" component={ContinentalParticipants} />
      <Route path="/continental-results" component={ContinentalResults} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/components" component={Components} />
      <Route path="/" component={LandingPage} />

    </Switch>
  </Router>,
  document.getElementById("root")
);
