import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import Timeline from "@material-ui/icons/Timeline";

import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";
import ListAlt from "@material-ui/icons/ListAlt";
import LabelImportant from "@material-ui/icons/LabelImportant";
import Event from "@material-ui/icons/Event";
import EventAvailable from "@material-ui/icons/EventAvailable";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Today from "@material-ui/icons/Today";

import EmojiEvents from '@material-ui/icons/EmojiEvents';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import Stars from '@material-ui/icons/Stars';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import InfoArea from "components/InfoArea/InfoArea.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";

import Badge from 'components/Badge/Badge.js';

import subaProfile from "assets/img/suba_white_gray.png";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ContinentalHouseRules(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

    return (
        <div>
            <Header
                color="transparent"
                brand="Suhanó Bárányok"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/suba_regulation.JPG")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.profile}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div>
                                            <img src={subaProfile} alt="..." className={imageClasses} />
                                        </div>
                                        <div className={classes.name}>
                                            <h2>CONTINENTAL 2022 - HÁZI REND / HOUSE RULES</h2>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.description}>
                            <h3>II. Az egyesület célja, tevékenysége</h3>
                            <ol>
                                <li>
                                    <p align="justify">
                                        Az egyesület célja: Juhászkutya fajták és azok tartásának népszerűsítése; ideértve a
                                        kutyasporttal, mint szabadidős tevékenységgel kapcsolatos feladatok ellátását és támogatását,
                                        így különösen, de nem kizárólagosan a betanítást, versenyeztetést. Valamint az ezekhez
                                        kapcsolódó szervezési, oktatási területen végzett tevékenységeket. Hagyományőrzés, terelő
                                        hagyományok bemutatása mellett célunk az egyéb kutyás sportok támogatása képzésekkel és
                                        vizsgák rendezésével egyaránt. Úgy mint, terápiás kutyák, kereső és munkakutyák képzése,
                                        vizsgáztatása, alkalmazása. Az egyesület működésével, céljával és tevékenységével elismeri és
                                        elfogadja az FCI mint nemzetközi kinológiai társaság valamint az őt képviselő Magyar
                                        Ebtenyésztők Országos Egyesületeinek Szövetsége alapelveit és szabályzatait.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az egyesület tevékenysége: Egyesületünk működésével, céljával és tevékenységével elismeri és
                                        elfogadja, magára nézve kötelező érvényűnek tartja az FCI mint nemzetközi kinológiai
                                        társaság alapelveit és szabályzatait. A cél megvalósulása érdekében biztosítja tagjai részére a
                                        foglalkozásokon, eseményeken történő részvételt, a sportolási lehetőségeket. A juhászkutya
                                        fajták számára versenyek szervezése. Nemzetközi tornákon való részvétel. Nemzetközi
                                        juhászkutya szervezetekkel való együttműködés. A terelő sport népszerűsítése előadások, illetve
                                        bemutatók tartásával. A border collie, az ausztrál kelpie, tradicionális fajták - mint a puli, mudi,
                                        pumi- és más terelő juhászkutya fajták népszerűsítése. A juhászkutyák tenyésztését támogató
                                        vizsgák és versenysport biztosítása. Az általunk szervezni kívánt vizsgák és versenyek
                                        elsősorban de nem kizárólagosan a következők:
                                        <ul>
                                            <li>
                                                Terelő ágazatban: FCI NHAT, HWT, IHT-CS I-III és IHT-TS-I-III, valamint egyéb
                                                terelőversenyek.
                                            </li>
                                            <li>
                                                Egyéb ágazatok: terápiás kutyák vizsgáztatása, BH vizsga, Obedience ágazat vizsgái,
                                                szarvasgomba kereső vizsga, egyéb munkakutya versenyek és vizsgák.
                                            </li>
                                        </ul>
                                        Tanfolyamok, konzultációk szervezése. Más sportszervezetekkel kialakított kapcsolatok
                                        ápolása. Versenyzők támogatása, szabadidős- és sporttevékenységek szervezése és
                                        lebonyolítása. A tömegsport népszerűsítése a fiatalság körében. Az egyesület tevékenységi
                                        területe nem korlátozódik Magyarország területére, tevékenységét a határon túl is kifejtheti. Az
                                        egyesület bármely kutyasportot, egészséges életmódot elősegítő célt támogathat, akár tagjai,
                                        akár kívülálló személyek egyéni, csoportos, tömegsport, vagy versenysport, tehetséggondozás,
                                        utánpótlás-nevelés, szabadidősport jellegű tevékenységéhez nyújtott támogatással. A támogatás
                                        célcsoportját képezhetik elsődlegesen kutyatartók, családtagjaik, illetve gyermekek, fiatalok,
                                        sportoktatók, sportra és egészséges életmódra, állattartásra nevelő oktatási-, és
                                        képzőintézmények, vagy azok fenntartói, szerveződései, projekttársulásai, konzorciumai,
                                        függetlenül attól, hogy azok állami, önkormányzati, egyházi, alapítványi, vagy egyéb
                                        fenntartásban, kezelésben, vagy irányítás alatt vannak, ideértve bármely egyéb nem
                                        intézményesült formában működő, vagy létrejövő kutyatartást, sportot, egészséges életmódot
                                        támogató szerveződést. Az egyesület állattartást, kutyatartást, sportolási, egészséges életmódot
                                        támogató célt megvalósító – hazai, vagy uniós forrásból megvalósuló – projektekhez is
                                        támogatást nyújthat, ilyenekben akár pályázóként, akár együttműködő partnerként részt vehet. A
                                        magyar terelő hagyományok bemutatása, magyar terelő fajták népszerűsítése.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az egyesület az egyesületi cél megvalósításával közvetlenül összefüggő gazdasági tevékenység
                                        végzésére jogosult.
                                    </p>
                                </li>
                            </ol>
                            <h3>III. Az egyesület működésére vonatkozó általános szabályok</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
