import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Camera from "@material-ui/icons/Camera";
// import Palette from "@material-ui/icons/Palette";
// import Favorite from "@material-ui/icons/Favorite";
// import Timeline from "@material-ui/icons/Timeline";

// import Schedule from "@material-ui/icons/Schedule";
// import List from "@material-ui/icons/List";
import ListAlt from "@material-ui/icons/ListAlt";
// import LabelImportant from "@material-ui/icons/LabelImportant";
// import Event from "@material-ui/icons/Event";
// import EventAvailable from "@material-ui/icons/EventAvailable";
// import InsertInvitation from "@material-ui/icons/InsertInvitation";
// import CalendarToday from "@material-ui/icons/CalendarToday";
// import Today from "@material-ui/icons/Today";

import EmojiEvents from '@material-ui/icons/EmojiEvents';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import Stars from '@material-ui/icons/Stars';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';


import InfoArea from "components/InfoArea/InfoArea.js";
import Warning from "components/Typography/Warning.js";

import Badge from 'components/Badge/Badge.js';

import subaProfile from "assets/img/suba_white_gray.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { Pets, HowToReg, DoorbellIcon, OutdoorGrill, House, Sports, Timer } from "@material-ui/icons";

// import Festival from '@mui/icons-material/Festival';

const useStyles = makeStyles(styles);

function createCompetitor(place, points, startOrder, name, nation, dogName, breed, sex) {
    return { place, points, startOrder, name, nation, dogName, breed, sex };
}

// division 1
const division1Participants = [
    createCompetitor(0, 0, 0, 'Agnieszka PIERGA', 'PL', 'Abi', 'Australian Kelpie', 'Female'),
    createCompetitor(0, 0, 0, 'Radochna IŁŁAKOWICZ', 'PL', 'Thorin', 'Australian Kelpie', 'Male'),
    createCompetitor(0, 0, 0, 'Gerlinde KRACHLER', 'AT', 'Aykendu', 'Bearded Collie', 'Male'),
    createCompetitor(0, 0, 0, 'Gerlinde KRACHLER', 'AT', 'Narnia', 'Bearded Collie', 'Female'),
    createCompetitor(0, 0, 0, 'ZELENA Gergőné', 'HU', 'Arya', 'Beauceron', 'Female'),
    createCompetitor(0, 0, 0, 'Petr SEREBRIANYI', 'CZ', 'Bacsi', 'Belgian Shepherd Tervueren', 'Male'),
    createCompetitor(0, 0, 0, 'BRISKINÉ PÁLFI Krisztina', 'HU', 'Sam', 'Briard', 'Male'),
    createCompetitor(0, 0, 0, 'Pierre KNOBEN', 'NL', 'Karlijn', 'Chien Berger des Pyrenees a poil long', 'Female'),
    createCompetitor(0, 0, 0, 'BELÉNYI Beatrix', 'HU', 'Zari', 'Malinois', 'Female'),
    createCompetitor(0, 0, 0, 'DOBÓ NAGY Csaba', 'HU', 'Göndör', 'Pumi', 'Female'),
    createCompetitor(0, 0, 0, 'KEDVES Krisztina ', 'HU', 'Rozi', 'Samoyed', 'Female'),

];

const division2Participants = [
    createCompetitor(0, 0, 0, 'Radochna IŁŁAKOWICZ', 'PL', 'Scarlet', 'Australian Kelpie', 'Female'),
    createCompetitor(0, 0, 0, 'Jiří ADAMEC', 'CZ', 'Bella', 'Australian Shepherd', 'Female'),
    createCompetitor(0, 0, 0, 'RAKAMAZI Adrienn', 'HU', 'Prime', 'Beauceron', 'Male'),
    createCompetitor(0, 0, 0, 'TÓTH Szandra', 'HU', 'Divine', 'Beauceron', 'Female'),
    createCompetitor(0, 0, 0, 'ZELENA Gergőné', 'HU', 'Marci', 'Beauceron', 'Male'),
    createCompetitor(0, 0, 0, 'BRISKINÉ PÁLFI Krisztina', 'HU', 'Chuck', 'Briard', 'Male'),
    createCompetitor(0, 0, 0, 'PÁNCZÉL Józsefné', 'HU', 'Buda', 'Groenendael', 'Male'),
    createCompetitor(0, 0, 0, 'PAPP Kamilla', 'HU', 'Newton', 'Rough collie', 'Male'),
    createCompetitor(0, 0, 0, 'Lucie ZETOVÁ', 'CZ', 'Vlasta', 'Welsh corgi cardigan', 'Male'),
];

const division3Participants = [
    createCompetitor(0, 0, 0, 'Małgorzata GOLEMAN', 'PL', 'Ex', 'Australian Kelpie', 'Male'),
    createCompetitor(0, 0, 0, 'Jana KAUTSKA', 'CZ', 'Maiky', 'Australian Kelpie', 'Male'),
    createCompetitor(0, 0, 0, 'Stanislav GABRIEL', 'CZ', 'Viki', 'Australian Kelpie', 'Female'),
    createCompetitor(0, 0, 0, 'Aleksandra BAGAEVA', 'RU', 'Light', 'Australian Kelpie', 'Male'),
    createCompetitor(0, 0, 0, 'Jana KAUTSKÁ', 'CZ', 'Ada', 'Australian Shepherd', 'Male'),
    createCompetitor(0, 0, 0, 'Jiří ADAMEC', 'CZ', 'Calamity Aussie', 'Australian Shepherd', 'Female'),
    createCompetitor(0, 0, 0, 'Kerstin MENNEMEIER', 'DE', 'Hilda', 'Bearded Collie', 'Female'),
    createCompetitor(0, 0, 0, 'Kerstin MENNEMEIER', 'DE', 'Joe-Ida', 'Bearded Collie', 'Female'),
    createCompetitor(0, 0, 0, 'Lenka TÁBORSKÁ', 'CZ', 'Cyra', 'Beauceron', 'Female'),
    createCompetitor(0, 0, 0, 'Lenka TÁBORSKÁ', 'CZ', 'Bára', 'Beauceron', 'Female'),
    createCompetitor(0, 0, 0, 'Tatiana BIEG', 'DE', 'Dyami', 'Beauceron', 'Male'),
    createCompetitor(0, 0, 0, 'CSÁSZÁR Vera', 'HU', 'Amina', 'Beauceron', 'Female'),
    createCompetitor(0, 0, 0, 'TÓTH Szandra', 'HU', 'Míta', 'Beauceron', 'Female'),
    createCompetitor(0, 0, 0, 'Eric PEETERS', 'FR', 'Jaina', 'Belgian Shepherd Tervueren', 'Female'),
    createCompetitor(0, 0, 0, 'Eric PEETERS', 'FR', 'Neva', 'Belgian Shepherd Tervueren', 'Female'),
    createCompetitor(0, 0, 0, 'Lucie SCHÖNOVÁ', 'CZ', 'Dump', 'Bohemian Shepherd', 'Male'),
    createCompetitor(0, 0, 0, 'Martina MIKULOVÁ', 'CZ', 'Rusty', 'Briard', 'Male'),
    createCompetitor(0, 0, 0, 'Maryna NEMCHENKO', 'UA', 'Airish', 'Briard', 'Male'),
    createCompetitor(0, 0, 0, 'HECZENDORFER István', 'HU', 'Cigle', 'Mudi', 'Female'),
    createCompetitor(0, 0, 0, 'LENNERT László', 'HU', 'Bogyó', 'Mudi', 'Male'),
    createCompetitor(0, 0, 0, 'BELÉNYI Beatrix', 'HU', 'Orka', 'Puli', 'Male'),
    createCompetitor(0, 0, 0, 'Nadezhda ULEDOVA', 'RU', 'Alaska', 'Shetland Sheepdog', 'Female'),
    createCompetitor(0, 0, 0, 'Nadezhda ULEDOVA', 'RU', 'Nancy', 'Shetland Sheepdog', 'Female'),
    createCompetitor(0, 0, 0, 'Lucie ZETOVÁ', 'CZ', 'Vilma', 'Welsh Corgi Cardigan', 'Female'),
    createCompetitor(0, 0, 0, 'Martirosyants Yulia', 'RU', 'Lisa', 'Welsh Corgi Cardigan', 'Female'),
    createCompetitor(0, 0, 0, 'Podlin ARTEM', 'UA', 'Aris', 'Welsh Corgi Pembroke', 'Male'),
    createCompetitor(0, 0, 0, 'Matěj Mikuláš Píše', 'CZ', 'Rika', 'White Swiss Shepherd', 'Female'),
];

const teamCompetition = [];

export default function ContinentalResults(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

    return (
        <div>
            <Header
                color="transparent"
                brand="Suhanó Bárányok"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/barik.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.profile}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div>
                                            <img src={subaProfile} alt="..." className={imageClasses} />
                                        </div>
                                        <div className={classes.name}>
                                            <h2>TS CONTINENTAL TRIAL 2022 <br/> Eredmények - Results</h2>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.description}>
                            <p align="justify">
                                Információk a versenyről:
                                <br />Az esemény időtartama: Április 14 Csütörtök 13.30 - Április 17 Vasárnap 20.00
                                <br />Helyszín: 8000 Székesfehérvár, Nyúl dűlő 14, Hungary
                                <br />Pályák: A, B, C
                            </p>
                            <p align="justify">
                                Informations about the competition:
                                <br />Duration of the event: 14 April Thursday 13.30 - 17 April Sunday 20.00
                                <br />Location: 8000 Székesfehérvár, Nyúl dűlő 14, Hungary
                                <br />Fields: A, B, C
                            </p>
                        </div>
                        {/* <h2>Pénteki Rajtsorrend?????</h2>
                        <h2>Szombati Rajtsorrend?????</h2>
                        <p>extra info???</p> */}
                        <h2>TS 1</h2>
                        <Table sx={{ minWidth: 650 }} aria-label="competitor div1">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Helyezés / Place</TableCell>
                                    <TableCell>Pontszám / Points</TableCell>
                                    <TableCell>Rajtszám / Start order</TableCell>
                                    <TableCell align="center">Felvezető / Handler</TableCell>
                                    <TableCell>Nemzetiség / Nation</TableCell>
                                    <TableCell>Hívónév / Call name</TableCell>
                                    <TableCell align="center">Fajta / Breed</TableCell>
                                    <TableCell>Ivar / Sex</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {division1Participants.map((participant) => (
                                    <TableRow
                                        key={participant.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{participant.place}</TableCell>
                                        <TableCell align="center">{participant.points}</TableCell>
                                        <TableCell align="center">{participant.startOrder}</TableCell>
                                        <TableCell>{participant.name}</TableCell>
                                        <TableCell align="center">{participant.nation}</TableCell>
                                        <TableCell>{participant.dogName}</TableCell>
                                        <TableCell>{participant.breed}</TableCell>
                                        <TableCell>{participant.sex}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* <h2>Pénteki Rajtsorrend?????</h2>
                        <h2>Szombati Rajtsorrend?????</h2>
                        <p>extra info???</p> */}
                        <h2>TS 2</h2>
                        <Table sx={{ minWidth: 650 }} aria-label="competitor div2">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Helyezés / Place</TableCell>
                                    <TableCell>Pontszám / Points</TableCell>
                                    <TableCell>Rajtszám / Start order</TableCell>
                                    <TableCell align="center">Felvezető / Handler</TableCell>
                                    <TableCell>Nemzetiség / Nation</TableCell>
                                    <TableCell>Hívónév / Call name</TableCell>
                                    <TableCell align="center">Fajta / Breed</TableCell>
                                    <TableCell>Ivar / Sex</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {division2Participants.map((participant) => (
                                    <TableRow
                                        key={participant.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{participant.place}</TableCell>
                                        <TableCell align="center">{participant.points}</TableCell>
                                        <TableCell align="center">{participant.startOrder}</TableCell>
                                        <TableCell>{participant.name}</TableCell>
                                        <TableCell align="center">{participant.nation}</TableCell>
                                        <TableCell>{participant.dogName}</TableCell>
                                        <TableCell>{participant.breed}</TableCell>
                                        <TableCell>{participant.sex}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* <h2>Pénteki Rajtsorrend?????</h2>
                        <h2>Szombati Rajtsorrend?????</h2>
                        <h2>Vasárnapi Rajtsorrend?????</h2>
                        <p>extra info???</p> */}
                        <h2>TS 3</h2>
                        <Table sx={{ minWidth: 650 }} aria-label="competitor div3">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Helyezés / Place</TableCell>
                                    <TableCell>Pontszám / Points</TableCell>
                                    <TableCell>Rajtszám / Start order</TableCell>
                                    <TableCell align="center">Felvezető / Handler</TableCell>
                                    <TableCell>Nemzetiség / Nation</TableCell>
                                    <TableCell>Hívónév / Call name</TableCell>
                                    <TableCell align="center">Fajta / Breed</TableCell>
                                    <TableCell>Ivar / Sex</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {division3Participants.map((participant) => (
                                    <TableRow
                                        key={participant.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{participant.place}</TableCell>
                                        <TableCell align="center">{participant.points}</TableCell>
                                        <TableCell align="center">{participant.startOrder}</TableCell>
                                        <TableCell>{participant.name}</TableCell>
                                        <TableCell align="center">{participant.nation}</TableCell>
                                        <TableCell>{participant.dogName}</TableCell>
                                        <TableCell>{participant.breed}</TableCell>
                                        <TableCell>{participant.sex}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* <h2>Csapatverseny / Team competition</h2>
                        <Table sx={{ minWidth: 650 }} aria-label="competitor team">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Helyezés / Place</TableCell>
                                    <TableCell>Pontszám / Points</TableCell>
                                    <TableCell>Rajtszám / Start order</TableCell>
                                    <TableCell align="center">Felvezető / Handler</TableCell>
                                    <TableCell>Nemzetiség / Nation</TableCell>
                                    <TableCell>Hívónév / Call name</TableCell>
                                    <TableCell align="center">Fajta / Breed</TableCell>
                                    <TableCell>Ivar / Sex</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teamCompetition.map((participant) => (
                                    <TableRow
                                        key={participant.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{participant.place}</TableCell>
                                        <TableCell align="center">{participant.points}</TableCell>
                                        <TableCell align="center">{participant.startOrder}</TableCell>
                                        <TableCell>{participant.name}</TableCell>
                                        <TableCell align="center">{participant.nation}</TableCell>
                                        <TableCell>{participant.dogName}</TableCell>
                                        <TableCell>{participant.breed}</TableCell>
                                        <TableCell>{participant.sex}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}
