import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import Timeline from "@material-ui/icons/Timeline";

import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";
import ListAlt from "@material-ui/icons/ListAlt";
import LabelImportant from "@material-ui/icons/LabelImportant";
import Event from "@material-ui/icons/Event";
import EventAvailable from "@material-ui/icons/EventAvailable";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Today from "@material-ui/icons/Today";

import EmojiEvents from '@material-ui/icons/EmojiEvents';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import Stars from '@material-ui/icons/Stars';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import InfoArea from "components/InfoArea/InfoArea.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";

import Badge from 'components/Badge/Badge.js';

import subaProfile from "assets/img/suba_white_gray.png";

import Petra from "assets/img/continentalJudges/Petra6.jpg";
import Sinikka from "assets/img/continentalJudges/Sinikka1.jpg";
import Gabi from "assets/img/faces/Gabi-profil3.JPG";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ContinentalJudges(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  return (
    <div>
      <Header
        color="transparent"
        brand="Suhanó Bárányok"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/suba_regulation.JPG")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <div className={classes.profile}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <div className={classes.profile}>
                    <div>
                      <img src={subaProfile} alt="..." className={imageClasses} />
                    </div>
                    <div className={classes.name}>
                      <h2>TS CONTINENTAL 2022 <br /> Bírók / Judges</h2>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div className={classes.section}>
              <h2 className={classes.title}>Bírók / Judges</h2>
              <div>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={4}>
                    <Card plain>
                      <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                        <img src={Sinikka} alt="..." className={imageClasses} />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                        <h4 className={classes.cardTitle}>
                          Sinikka Kumpusalmi
                          <br />
                          <small className={classes.smallTitle}>Finland</small>
                        </h4>
                        <CardBody>
                          <p className={classes.description}>
                            Hi, here is my experience shortly. -First Giant Schnauzer I bought when I was16years of age -Since that I had same breed in obedience, tracking, herding. -bred Giant Schnauzer for 25 years, but gave out for their growing health issues inside whole breed - professional dairy goat and sheep farmer from 1998, from year 2008 only sheep farming -in Finland started as first professional herding trainer for all herding breeds from 1999 -have been training annualy proximum ‪350‬ dogs since that -became first AHBA judge in Europe -arranged first AHBA trials in 2008 -started working for getting FCI traditional herding style official trial format in Finland -was one of first official judges in Finland, 2012 -annually tests 40-60 NHAT, judges proximum 50-100 in trial classes in Finland and Europe -international judging in many trials in Europe, Swizerland championships, Swizerland Cup, Czech Australian breed Ch, Holland ect. -have trialed with Giant Schnauzer, border collie till 3rd class in AHBA, HWT with Bouvier des Ardennes in FCI traditional herding style (my own dogs are for work and I have no time neither desire on trialing myself  ) -have trained dogs and hold clinics, seminars for all breeds in Finland and different European countries -board member in Finnish herding commitee for two seasons (last time presented there German Shepherds Society of Finland) -‪2017-18‬ marketing person in Karelian beardog society -founder, author and editor of international herding magazine callef Herdingworld for three years -author for two herding books -own dogs Bouvier des Ardennes, Norwegian Elkhund, Cao Serra des Aires, 4 Australian koolie, 2 working border collies, Chesapeake retriever, Gorgi -hobby: working, hunting,baking,bhandcraft,writing Official education: -Baker -AD/Copywriter -Gardener Yours, Sinikka Kumpusalmi
                          </p>
                        </CardBody>
                      </GridItem>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Card plain>
                      <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                        <img src={Petra} alt="..." className={imageClasses} />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                        <h4 className={classes.cardTitle}>
                          Szarvas Petra
                          <br />
                          <small className={classes.smallTitle}>Hungary</small>
                        </h4>
                        <CardBody>
                          <p className={classes.description}>
                            I had my first Border collie, Milly 15 years ago. It was natural for me to try out herding with her, as she was from a farm and her parents were herding sheep daily. When she was one year old in 2007, we took part on a herding event and we tried this sport for the first time. Since then I'm a huge addict on herding and working with sheepdogs.
                            In the first years I mostly focused on my own dogs, but I was always keen on working with other people's dogs, as well. Mostly because I find the different kind of sheepdog breeds' work fascinating, and I wanted to study their herding style, too. In 2012, our family bought a farm and sheep, since then I've been training there with people from all around the continent.
                            In the past 14 years I've achieved many results with my 4 dogs, just to mention a few:
                            - Hungarian Herding Champion 5 times
                            - Winner of the Czech Open qualification runs in 2016, 5th in the final
                            - Czech Open 4th place in the final
                            - French Open 2015, Nursery class 3rd place
                            - French Open 2016 9th place
                            - HSDS Champion 3 times
                            - 3rd place at the Italian Dolomiti Open 2020
                            - many trial placements in European trials
                            - Continental Team member with 2 dogs
                            Beside these results, I've been training with other people's dogs for 10 years, all different kind of breeds from both styles. I'm very proud of my students, who have  passed the FCI Herding Working Test (both Border collies and Interbreed), competing in Traditional or Collecting Style IHT- 1-3. It is easier to tell, which breed I haven't work with yet, because from French, Belgian shepherds to Corgis, I've trained many different dogs. I've held clinics for all breeds all over Europe (Czech Republic, Slovenia, Italy, Lithuania, Norway, etc.). I've also judged trials and exams in many different countries in both FCI styles. The traditional style has a special place in my heart, because I admire those dogs who are able to compete on the highest levels and show off their best working abilities.
                            I work as a sheep consultant as well, anybody having problem with shearing or finding their sheep, I'll go and help them out with my dogs. During high school and university In the summer I worked many years as a shepherd for a flock with 500 sheeps. I gained a lot of experience on farm work by this.

                          </p>
                        </CardBody>
                      </GridItem>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Card plain>
                      <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                        <img src={Gabi} alt="..." className={imageClasses} />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                        <h4 className={classes.cardTitle}>
                          Patkó Gabriella
                          <br />
                          <small className={classes.smallTitle}>Hungary</small>
                        </h4>
                        <CardBody>
                          <p className={classes.description}>
                            Since 1999 I do herding with my dogs. First with pumi, after 2000 also with border collie.
                            My dogs worked with my 150 sheep, 4 cows, donkey on my own farm.
                            My dogs had to learn the real farm-dog works grazing, protecting from traffic, protecting the sowing from sheep, crossing railway, etc.
                            In the beginning only a few people came to learn herding, but till today it grow, so now I have experience with almost every traditional herding breed, and also border collies.
                            Many people ask, which is the best herding breed? I always answer: if a dog is good, it is no matter, which breed. I love to work with all of them.
                            I judged already in many countries, and those, who know me, know, I like dogs with strong instincts. I am planning my trails with tasks of the everyday farm-life.
                          </p>
                        </CardBody>
                      </GridItem>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
