import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import Timeline from "@material-ui/icons/Timeline";

import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";
import ListAlt from "@material-ui/icons/ListAlt";
import LabelImportant from "@material-ui/icons/LabelImportant";
import Event from "@material-ui/icons/Event";
import EventAvailable from "@material-ui/icons/EventAvailable";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Today from "@material-ui/icons/Today";

import EmojiEvents from '@material-ui/icons/EmojiEvents';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import Stars from '@material-ui/icons/Stars';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import InfoArea from "components/InfoArea/InfoArea.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";

import Badge from 'components/Badge/Badge.js';

import subaProfile from "assets/img/suba_white_gray.png";
import continentalMap from "assets/img/continentalInfo/continental_map.png";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ContinentalInformation(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

    return (
        <div>
            <Header
                color="transparent"
                brand="Suhanó Bárányok"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/suba_regulation.JPG")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.profile}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div>
                                            <img src={subaProfile} alt="..." className={imageClasses} />
                                        </div>
                                        <div className={classes.name}>
                                            <h2>TS CONTINENTAL TRIAL 2022 <br /> INFORMÁCIÓ / INFORMATION</h2>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.description}>
                            <p align="justify">
                                Információk a versenyről:
                                <br />Az esemény időtartama: Április 14 Csütörtök 13.30 - Április 17 Vasárnap 20.00
                                <br />Helyszín: 8000 Székesfehérvár, Nyúl dűlő 14, Hungary
                                <br />Pályák: A, B, C
                            </p>
                            <p align="justify">
                                Informations about the competition:
                                <br />Duration of the event: 14 April Thursday 13.30 - 17 April Sunday 20.00
                                <br />Location: 8000 Székesfehérvár, Nyúl dűlő 14, Hungary
                                <br />Fields: A, B, C
                            </p>
                            <h3>Térkép - Map</h3>
                            <img src={continentalMap} className={imageClasses}></img>
                            <h3>Csapatverseny</h3>
                            <p>Készülünk csapatversennyel is nektek. Ennek a nevezése díjtalan.
                                Csapatba 3 kutya nevezhető. A csapat összetétele mindhárom osztályból lehet, tehát 1 csapatban lehet 1-es, 2-es és 3-as osztályos kutya is.
                                A csapatok nevezésére a regisztrációnál lesz lehetőségetek.
                                <br />
                                A csapatversenyen, a fajták, fajtacsoportok, országok legjobbjainak kiválasztásánál a következő pontszámítást alkalmazzuk:
                                <br />
                                <ol>
                                    <ul>TS-1: kapott pontok 100%-a</ul>
                                    <ul>TS-2: kapott pontok 105%-a</ul>
                                    <ul>TS-3: kapott pontok 110%-a</ul>
                                </ol>
                                <br /></p>
                            <h3>Team competition</h3>
                            <p>We are preparing a competition for teams. Entry is free. 3 dogs can be entered into a team. The composition of the team can be from all
                                three classes, so one team can also have dogs from TS-1, TS-2, TS-3 class. You will be able to register teams at the registration.
                                <br />
                                In the team competition, and when selecting the best of breeds, the best of breed groups and the best of countries, we will use the following scoring:                                 <br />
                                <ol>
                                    <ul>TS-1: 100% of points obtained </ul>
                                    <ul>TS-2: 105% of points obtained</ul>
                                    <ul>TS-3: 110% of points obtained</ul>
                                </ol>
                                <br /></p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
