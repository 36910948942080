import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Camera from "@material-ui/icons/Camera";
// import Palette from "@material-ui/icons/Palette";
// import Favorite from "@material-ui/icons/Favorite";
// import Timeline from "@material-ui/icons/Timeline";

// import Schedule from "@material-ui/icons/Schedule";
// import List from "@material-ui/icons/List";
import ListAlt from "@material-ui/icons/ListAlt";
// import LabelImportant from "@material-ui/icons/LabelImportant";
// import Event from "@material-ui/icons/Event";
// import EventAvailable from "@material-ui/icons/EventAvailable";
// import InsertInvitation from "@material-ui/icons/InsertInvitation";
// import CalendarToday from "@material-ui/icons/CalendarToday";
// import Today from "@material-ui/icons/Today";

import EmojiEvents from '@material-ui/icons/EmojiEvents';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import Stars from '@material-ui/icons/Stars';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import MuiTable from "@material-ui/core/Table";
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableBody from '@material-ui/core/TableBody';

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';


import InfoArea from "components/InfoArea/InfoArea.js";
import Warning from "components/Typography/Warning.js";

import Badge from 'components/Badge/Badge.js';

import subaProfile from "assets/img/suba_white_gray.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { Pets, HowToReg, DoorbellIcon, OutdoorGrill, House, Sports, Timer } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";

// import Festival from '@mui/icons-material/Festival';

// const styles = theme => ({
//     root: {
//         display: 'flex',
//         marginTop: theme.spacing.unit * 3,
//         overflowX: 'hide',
//     },
//     table: {
//         minWidth: 340,
//     },
//     tableCell: {
//         paddingRight: 4,
//         paddingLeft: 5
//     }
// });

const useStyles = makeStyles(styles);



// const Table = withStyles(theme => ({
//     root: {
//         maxWidth: 340,
//         minWidth: 340,
//         // padding: 0,
//         overflowX: "auto",
//         display: "flex"
//     },
//     table: {
//         // minWidth: 650,
//         maxWidth: 340,
//         minWidth: 340,
//         overflowX: "auto",
//         display: "flex"
//     },
//     tableCell: {
//         paddingRight: 4,
//         paddingLeft: 5
//     }
// }))(MuiTable);

// const TableHead = withStyles(theme => ({
//     root: {
//         maxWidth: 340,
//         minWidth: 340,
//         // padding: 0,
//         overflowX: "auto",
//         display: "flex"
//     }
//     // table: {
//     //     // minWidth: 650,
//     //     maxWidth: 340,
//     //     minWidth: 340,
//     //     overflowX: "auto",
//     //     display: "flex"
//     // }
//     // tableCell: {
//     //     paddingRight: 4,
//     //     paddingLeft: 5
//     // }
// }))(MuiTableHead);

// const TableBody = withStyles(theme => ({
//     root: {
//         maxWidth: 340,
//         minWidth: 340,
//         // padding: 0,
//         overflowX: "auto",
//         display: "flex"
//     },
//     table: {
//         // minWidth: 650,
//         maxWidth: 340,
//         minWidth: 340,
//         overflowX: "auto",
//         display: "flex"
//     },
//     tableCell: {
//         paddingRight: 4,
//         paddingLeft: 5
//     }
// }))(MuiTableBody);


function createParticipant(division, startNumber, name, nation, dogName, breed, sex) {
    return { division, startNumber, name, nation, dogName, breed, sex };
}

// division 1
const division1Participants = [
    createParticipant('TS-1', 1, 'Gerlinde KRACHLER', 'AT', 'Narnia', 'Bearded Collie', 'Szuka / Female'),
    createParticipant('TS-1', 2, 'DOBÓ NAGY Csaba', 'HU', 'Göndör', 'Pumi', 'Szuka / Female'),
    createParticipant('TS-1', 3, 'Radochna IŁŁAKOWICZ', 'PL', 'Thorin', 'Australian Kelpie', 'Kan / Male'),
    createParticipant('TS-1', 4, 'FODOR Attila', 'HU', 'Koa', 'Australian Kelpie', 'Kan / Male'),
    createParticipant('TS-1', 5, 'KEDVES Krisztina', 'HU', 'Rozi', 'Samoyed', 'Szuka / Female'),
    createParticipant('TS-1', 6, 'BRISKINÉ PÁLFI Krisztina', 'HU', 'Sam', 'Briard', 'Kan / Male'),
    createParticipant('TS-1', 7, 'Gerlinde KRACHLER', 'AT', 'Aykendu', 'Bearded Collie', 'Kan / Male'),
    createParticipant('TS-1', 8, 'Magali VENEAU', 'FR', 'Rachel', 'Groenendael', 'Szuka / Female'),
    createParticipant('TS-1', 9, 'ZELENA Gergőné', 'HU', 'Arya', 'Beauceron', 'Szuka / Female'),
    createParticipant('TS-1', 10, 'Pierre KNOBEN', 'NL', 'Karlijn', 'Chien Berger des Pyrenees a poil long', 'Szuka / Female'),
    createParticipant('TS-1', 11, 'KHEIREDDINE Amira', 'HU', 'Zoya', 'Malinois', 'Szuka / Female'),
    createParticipant('TS-1', 12, 'Agnieszka PIERGA', 'PL', 'Abi', 'Australian Kelpie', 'Szuka / Female'),
    createParticipant('TS-1', 13, 'SZABÓ Lóránt', 'HU', 'Ciklon', 'Welsh corgi cardigan', 'Kan / Male'),
    createParticipant('TS-1', 14, 'Petr SEREBRIANYI', 'CZ', 'Bacsi', 'Belgian Shepherd Tervueren', 'Kan / Male'),
    createParticipant('TS-1', 15, 'Birgit ZAHORAK', 'AT', 'Lollipop', 'Bearded Collie', 'Kan / Male'),
    createParticipant('TS-1', 16, 'Eric PEETERS', 'FR', 'Roma', 'Belgian Shepherd Tervueren', 'Szuka / Female'),
    createParticipant('TS-1', 17, 'BELÉNYI Beatrix', 'HU', 'Zari', 'Malinois', 'Szuka / Female'),
    // createParticipant('TS-1', 0, 'Tony CONTARESE', 'IT', 'Maillot', 'Belgian Shepherd', 'Kan / Male'),
    // createParticipant('TS-1', 0, 'Ursula LOIBL', 'AT', 'Lady', 'Australian Shepherd', 'Szuka / Female'),
];

const division2Participants = [
    createParticipant('TS-2', 1, 'Jiří ADAMEC', 'CZ', 'Bella', 'Australian Shepherd', 'Szuka / Female'),
    createParticipant('TS-2', 2, 'Radochna IŁŁAKOWICZ', 'PL', 'Scarlet', 'Australian Kelpie', 'Szuka / Female'),
    createParticipant('TS-2', 3, 'PAPP Kamilla', 'HU', 'Newton', 'Rough collie', 'Kan / Male'),
    createParticipant('TS-2', 4, 'Pier Paolo BRUSCUGLIA', 'IT', 'Maude', 'Belgian Shepherd', 'Szuka / Female'),
    createParticipant('TS-2', 5, 'PÁNCZÉL Józsefné', 'HU', 'Buda', 'Groenendael', 'Kan / Male'),
    createParticipant('TS-2', 6, 'RAKAMAZI Adrienn', 'HU', 'Prime', 'Beauceron', 'Kan / Male'),
    createParticipant('TS-2', 7, 'Lucie ZETOVÁ', 'CZ', 'Vlasta', 'Welsh corgi cardigan', 'Kan / Male'),
    createParticipant('TS-2', 8, 'BRISKINÉ PÁLFI Krisztina', 'HU', 'Chuck', 'Briard', 'Kan / Male'),
    createParticipant('TS-2', 9, 'TÓTH Szandra', 'HU', 'Divine', 'Beauceron', 'Szuka / Female'),
    createParticipant('TS-2', 10, 'ZELENA Gergőné', 'HU', 'Marci', 'Beauceron', 'Kan / Male'),
];

const fieldAFriday = [
    createParticipant('TS-3', 1, 'Lenka TÁBORSKÁ', 'CZ', 'Cyra', 'Beauceron', 'Szuka / Female'),
    createParticipant('TS-3', 2, 'Jana KAUTSKÁ', 'CZ', 'Ada', 'Australian Shepherd', 'Kan / Male'),
    createParticipant('TS-3', 3, 'Jiří ADAMEC', 'CZ', 'Calamity Aussie', 'Australian Shepherd', 'Szuka / Female'),
    createParticipant('TS-3', 4, 'BELÉNYI Beatrix', 'HU', 'Orka', 'Puli', 'Kan / Male'),
    createParticipant('TS-3', 5, 'Lucie ZETOVÁ', 'CZ', 'Vilma', 'Welsh Corgi Cardigan', 'Szuka / Female'),
    createParticipant('TS-3', 6, 'Jana KAUTSKA', 'CZ', 'Maiky', 'Australian Kelpie', 'Kan / Male'),
    createParticipant('TS-3', 7, 'LENNERT László', 'HU', 'Bogyó', 'Mudi', 'Kan / Male'),
    createParticipant('TS-3', 8, 'Katerina VOLEJNIK', 'CZ', 'Lucky', 'Belgian Sheepherd Laekenois', 'Szuka / Female'),
    createParticipant('TS-3', 9, 'Ivana RUZICKOVA', 'CZ', 'Soki', 'Smooth Collie', 'Kan / Male'),
    createParticipant('TS-3', 10, 'Martina MIKULOVÁ', 'CZ', 'Rusty', 'Briard', 'Kan / Male'),
    createParticipant('TS-3', 11, 'Lenka TÁBORSKÁ', 'CZ', 'Bára', 'Beauceron', 'Szuka / Female'),
    createParticipant('TS-2', 1, 'Jiří ADAMEC', 'CZ', 'Bella', 'Australian Shepherd', 'Szuka / Female'),
    createParticipant('TS-2', 2, 'Radochna IŁŁAKOWICZ', 'PL', 'Scarlet', 'Australian Kelpie', 'Szuka / Female'),
    createParticipant('TS-2', 3, 'PAPP Kamilla', 'HU', 'Newton', 'Rough collie', 'Kan / Male'),
    createParticipant('TS-2', 4, 'Pier Paolo BRUSCUGLIA', 'IT', 'Maude', 'Belgian Shepherd', 'Szuka / Female'),
    createParticipant('TS-2', 5, 'PÁNCZÉL Józsefné', 'HU', 'Buda', 'Groenendael', 'Kan / Male')
];

const fieldBFriday = [
    createParticipant('TS-3', 12, 'Eric PEETERS', 'FR', 'Jaina', 'Belgian Shepherd Tervueren', 'Szuka / Female'),
    createParticipant('TS-3', 13, 'Lucie SCHÖNOVÁ', 'CZ', 'Dump', 'Bohemian Shepherd', 'Kan / Male'),
    createParticipant('TS-3', 14, 'Tatiana BIEG', 'DE', 'Dyami', 'Beauceron', 'Kan / Male'),
    createParticipant('TS-3', 15, 'Eric PEETERS', 'FR', 'Neva', 'Belgian Shepherd Tervueren', 'Szuka / Female'),
    createParticipant('TS-3', 16, 'HECZENDORFER István', 'HU', 'Cigle', 'Mudi', 'Szuka / Female'),
    createParticipant('TS-3', 17, 'TÓTH Szandra', 'HU', 'Míta', 'Beauceron', 'Szuka / Female'),
    createParticipant('TS-3', 18, 'Kerstin MENNEMEIER', 'DE', 'Hilda', 'Bearded Collie', 'Szuka / Female'),
    createParticipant('TS-3', 19, 'Małgorzata GOLEMAN', 'PL', 'Ex', 'Australian Kelpie', 'Kan / Male'),
    createParticipant('TS-3', 20, 'Matěj Mikuláš PÍŠE', 'CZ', 'Rika', 'White Swiss Shepherd', 'Szuka / Female'),
    createParticipant('TS-3', 21, 'Kerstin MENNEMEIER', 'DE', 'Joe-Ida', 'Bearded Collie', 'Szuka / Female'),
    createParticipant('TS-3', 22, 'CSÁSZÁR Vera', 'HU', 'Amina', 'Beauceron', 'Szuka / Female'),
    createParticipant('TS-2', 6, 'RAKAMAZI Adrienn', 'HU', 'Prime', 'Beauceron', 'Kan / Male'),
    createParticipant('TS-2', 7, 'Lucie ZETOVÁ', 'CZ', 'Vlasta', 'Welsh corgi cardigan', 'Kan / Male'),
    createParticipant('TS-2', 8, 'BRISKINÉ PÁLFI Krisztina', 'HU', 'Chuck', 'Briard', 'Kan / Male'),
    createParticipant('TS-2', 9, 'TÓTH Szandra', 'HU', 'Divine', 'Beauceron', 'Szuka / Female'),
    createParticipant('TS-2', 10, 'ZELENA Gergőné', 'HU', 'Marci', 'Beauceron', 'Kan / Male')
];

const fieldCFriday = [

];

const division3Participants = [
    createParticipant('TS-3', 1, 'Lenka TÁBORSKÁ', 'CZ', 'Cyra', 'Beauceron', 'Szuka / Female'),
    createParticipant('TS-3', 2, 'Jana KAUTSKÁ', 'CZ', 'Ada', 'Australian Shepherd', 'Kan / Male'),
    createParticipant('TS-3', 3, 'Jiří ADAMEC', 'CZ', 'Calamity Aussie', 'Australian Shepherd', 'Szuka / Female'),
    createParticipant('TS-3', 4, 'BELÉNYI Beatrix', 'HU', 'Orka', 'Puli', 'Kan / Male'),
    createParticipant('TS-3', 5, 'Lucie ZETOVÁ', 'CZ', 'Vilma', 'Welsh Corgi Cardigan', 'Szuka / Female'),
    createParticipant('TS-3', 6, 'Jana KAUTSKA', 'CZ', 'Maiky', 'Australian Kelpie', 'Kan / Male'),
    createParticipant('TS-3', 7, 'LENNERT László', 'HU', 'Bogyó', 'Mudi', 'Kan / Male'),
    createParticipant('TS-3', 8, 'Katerina VOLEJNIK', 'CZ', 'Lucky', 'Belgian Sheepherd Laekenois', 'Szuka / Female'),
    createParticipant('TS-3', 9, 'Ivana RUZICKOVA', 'CZ', 'Soki', 'Smooth Collie', 'Kan / Male'),
    createParticipant('TS-3', 10, 'Martina MIKULOVÁ', 'CZ', 'Rusty', 'Briard', 'Kan / Male'),
    createParticipant('TS-3', 11, 'Lenka TÁBORSKÁ', 'CZ', 'Bára', 'Beauceron', 'Szuka / Female'),
    createParticipant('TS-3', 12, 'Eric PEETERS', 'FR', 'Jaina', 'Belgian Shepherd Tervueren', 'Szuka / Female'),
    createParticipant('TS-3', 13, 'Lucie SCHÖNOVÁ', 'CZ', 'Dump', 'Bohemian Shepherd', 'Kan / Male'),
    createParticipant('TS-3', 14, 'Tatiana BIEG', 'DE', 'Dyami', 'Beauceron', 'Kan / Male'),
    createParticipant('TS-3', 15, 'Eric PEETERS', 'FR', 'Neva', 'Belgian Shepherd Tervueren', 'Szuka / Female'),
    createParticipant('TS-3', 16, 'HECZENDORFER István', 'HU', 'Cigle', 'Mudi', 'Szuka / Female'),
    createParticipant('TS-3', 17, 'TÓTH Szandra', 'HU', 'Míta', 'Beauceron', 'Szuka / Female'),
    createParticipant('TS-3', 18, 'Kerstin MENNEMEIER', 'DE', 'Hilda', 'Bearded Collie', 'Szuka / Female'),
    createParticipant('TS-3', 19, 'Małgorzata GOLEMAN', 'PL', 'Ex', 'Australian Kelpie', 'Kan / Male'),
    createParticipant('TS-3', 20, 'Matěj Mikuláš PÍŠE', 'CZ', 'Rika', 'White Swiss Shepherd', 'Szuka / Female'),
    createParticipant('TS-3', 21, 'Kerstin MENNEMEIER', 'DE', 'Joe-Ida', 'Bearded Collie', 'Szuka / Female'),
    createParticipant('TS-3', 22, 'CSÁSZÁR Vera', 'HU', 'Amina', 'Beauceron', 'Szuka / Female'),
    // createParticipant('TS-3', 0, 'Maryna NEMCHENKO', 'UA', 'Airish', 'Briard', 'Kan / Male'),
    // createParticipant('TS-3', 0, 'Stanislav GABRIEL', 'CZ', 'Viki', 'Australian Kelpie', 'Szuka / Female'),
    // createParticipant('TS-3', 0, 'Aleksandra BAGAEVA', 'RU', 'Light', 'Australian Kelpie', 'Kan / Male'),
    // createParticipant('TS-3', 0, 'Nadezhda ULEDOVA', 'RU', 'Alaska', 'Shetland Sheepdog', 'Szuka / Female'),
    // createParticipant('TS-3', 0, 'Nadezhda ULEDOVA', 'RU', 'Nancy', 'Shetland Sheepdog', 'Szuka / Female'),
    // createParticipant('TS-3', 0, 'Martirosyants Yulia', 'RU', 'Lisa', 'Welsh Corgi Cardigan', 'Szuka / Female'),
    // createParticipant('TS-3', 0, 'Podlin ARTEM', 'UA', 'Aris', 'Welsh Corgi Pembroke', 'Kan / Male'),
];

const fieldASaturday = [

];

const fieldBSaturday = [

];

const fieldCSaturday = [

];

export default function ContinentalParticipants(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);



    return (
        <div>
            <Header
                color="transparent"
                brand="Suhanó Bárányok"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/barik.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.profile}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div>
                                            <img src={subaProfile} alt="..." className={imageClasses} />
                                        </div>
                                        <div className={classes.name}>
                                            <h2>TS-CONTINENTAL TRIAL 2022 <br /> Rajtsorrend / Start order</h2>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.description}>
                            <p align="justify">
                                Információk a versenyről:
                                <br />Az esemény időtartama: Április 14 Csütörtök 13.30 - Április 17 Vasárnap 20.00
                                <br />Helyszín: 8000 Székesfehérvár, Nyúl dűlő 14, Hungary
                                <br />Pályák: A, B, C
                            </p>
                            <p align="justify">
                                Informations about the competition:
                                <br />Duration of the event: 14 April Thursday 13.30 - 17 April Sunday 20.00
                                <br />Location: 8000 Székesfehérvár, Nyúl dűlő 14, Hungary
                                <br />Fields: A, B, C
                            </p>
                        </div>
                        <h2>FIELD A - 2022.04.15. PÉNTEK / FRIDAY<br/>Bíró / Jugde: Sinikka Kumpusalmi</h2>
                        {/* TODO status bar vagy warning uzi, valami kiemelest ide az osztalyhoz */}
                        <Table sx={{ minWidth: 650 }} aria-label="field a friday">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Osztály / Class</TableCell>
                                    <TableCell>Rajtszám / Start order</TableCell>
                                    <TableCell align="center">Felvezető / Handler</TableCell>
                                    <TableCell>Nemzetiség / Nation</TableCell>
                                    <TableCell>Hívónév / Call name</TableCell>
                                    <TableCell align="center">Fajta / Breed</TableCell>
                                    <TableCell>Ivar / Sex</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fieldAFriday.map((participant) => (
                                    <TableRow
                                        key={participant.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{participant.division}</TableCell>
                                        <TableCell align="center">{participant.startNumber}</TableCell>
                                        <TableCell>{participant.name}</TableCell>
                                        <TableCell align="center">{participant.nation}</TableCell>
                                        <TableCell>{participant.dogName}</TableCell>
                                        <TableCell>{participant.breed}</TableCell>
                                        <TableCell>{participant.sex}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <h2>FIELD B - 2022.04.15. PÉNTEK / FRIDAY<br/>Bíró / Jugde: Petra Szarvas</h2>
                        <Table sx={{ minWidth: 650 }} aria-label="field b friday">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Osztály / Class</TableCell>
                                    <TableCell>Rajtszám / Start order</TableCell>
                                    <TableCell align="center">Felvezető / Handler</TableCell>
                                    <TableCell>Nemzetiség / Nation</TableCell>
                                    <TableCell>Hívónév / Call name</TableCell>
                                    <TableCell align="center">Fajta / Breed</TableCell>
                                    <TableCell>Ivar / Sex</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fieldBFriday.map((participant) => (
                                    <TableRow
                                        key={participant.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{participant.division}</TableCell>
                                        <TableCell align="center">{participant.startNumber}</TableCell>
                                        <TableCell>{participant.name}</TableCell>
                                        <TableCell align="center">{participant.nation}</TableCell>
                                        <TableCell>{participant.dogName}</TableCell>
                                        <TableCell>{participant.breed}</TableCell>
                                        <TableCell>{participant.sex}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <h2>FIELD C - 2022.04.15. PÉNTEK / FRIDAY<br/>Bíró / Judge: Gabriella Patkó</h2>
                        <Table sx={{ minWidth: 650 }} aria-label="participant div3">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Osztály / Class</TableCell>
                                    <TableCell>Rajtszám / Start order</TableCell>
                                    <TableCell align="center">Felvezető / Handler</TableCell>
                                    <TableCell>Nemzetiség / Nation</TableCell>
                                    <TableCell>Hívónév / Call name</TableCell>
                                    <TableCell align="center">Fajta / Breed</TableCell>
                                    <TableCell>Ivar / Sex</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {division1Participants.map((participant) => (
                                    <TableRow
                                        key={participant.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{participant.division}</TableCell>
                                        <TableCell align="center">{participant.startNumber}</TableCell>
                                        <TableCell>{participant.name}</TableCell>
                                        <TableCell align="center">{participant.nation}</TableCell>
                                        <TableCell>{participant.dogName}</TableCell>
                                        <TableCell>{participant.breed}</TableCell>
                                        <TableCell>{participant.sex}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}
