/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import { Apps, Home, CloudDownload } from "@material-ui/icons";
import HomeIcon from "@material-ui/icons/Home";
import EmojiEvents from '@material-ui/icons/EmojiEvents';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";


import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="TS CONTINENTAL TRIAL 2022"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={EmojiEvents}
          dropdownList={[
            // <Link to="/continental-house-rules" className={classes.dropdownLink}>
            //   Házirend / House Rules (TBD)
            // </Link>,
            // <Link to="/" className={classes.dropdownLink}>
            //   Szabályzatok
            // </Link>,
            // <Link to="/" className={classes.dropdownLink}>
            //   Információk
            // </Link>,
            <Link to="/continental-program" className={classes.dropdownLink}>
              Programok / Programs
            </Link>,
            <Link to="/continental-information" className={classes.dropdownLink}>
              Információ / Information
            </Link>,
            <Link to="/continental-judges" className={classes.dropdownLink}>
              Bírók / Judges
            </Link>,
            <Link to="/continental-participants" className={classes.dropdownLink}>
              Résztvevők / Participants
            </Link>,
            // <Link to="/continental-results" className={classes.dropdownLink}>
            //   Eredmények / Results (TODO)
            // </Link>,
            <Link to="/continental-sponsors" className={classes.dropdownLink}>
              Szponzorok / Sponsors
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="MENU"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/suba-regulations" className={classes.dropdownLink}>
              SuBa Alapszabály
            </Link>,
            // <Link to="/" className={classes.dropdownLink}>
            //   Szabályzatok
            // </Link>,
            // <Link to="/" className={classes.dropdownLink}>
            //   Információk
            // </Link>,
            <Link to="/program" className={classes.dropdownLink}>
              Eseményeink
            </Link>
            // ,
            // <Link to="/" className={classes.dropdownLink}>
            //   Múltbeli Rendezvényeink
            // </Link>,
            // <Link to="/" className={classes.dropdownLink}>
            //   Galéria
            // </Link>,
            // <Link to="/" className={classes.dropdownLink}>
            //   Eredményeink
            // </Link>,
            // <Link to="/" className={classes.dropdownLink}>
            //   Regisztráció
            // </Link>,
            // <Link to="/" className={classes.dropdownLink}>
            //   Jelentkezés
            // </Link>,


            // <Link to="" className={classes.dropdownLink}>
            //   Események
            // </Link>,
            // <Link to="/team-section" className={classes.dropdownLink}>
            //   Tagok
            // </Link>,
            // <a href="https://creativetimofficial.github.io/material-kit-react/#/documentation?ref=mkr-navbar"
            //   target="_blank"
            //   className={classes.dropdownLink}>
            //   Események
            // </a>
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Nézz szét a Facebook oldalunkon is"
          // Kövess minket facebook-on
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/SuBa-official-Suhan%C3%B3-B%C3%A1r%C3%A1nyok-Kuty%C3%A1s-Szabadid%C5%91-Egyes%C3%BClet-109231927461780/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Kövess minket youtube-on"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/SuBa-official-Suhan%C3%B3-B%C3%A1r%C3%A1nyok-Kuty%C3%A1s-Szabadid%C5%91-Egyes%C3%BClet-109231927461780/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-youtube"} />
          </Button>
        </Tooltip>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            // href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Tooltip
          id="home-page"
          title="Kezdőlap"
          // Kövess minket facebook-on
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton
            color="transparent"
            href="/landing-page"
            // target="_blank"
            className={classes.navLink}
          // buttonIcon={Apps}
          >
            {/* <i className={classes.socialIcons + " fab home"} /> */}
            <Home />
          </IconButton>
        </Tooltip>
      </ListItem>
    </List>
  );
}
