import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import Timeline from "@material-ui/icons/Timeline";

import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";
import ListAlt from "@material-ui/icons/ListAlt";
import LabelImportant from "@material-ui/icons/LabelImportant";
import Event from "@material-ui/icons/Event";
import EventAvailable from "@material-ui/icons/EventAvailable";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Today from "@material-ui/icons/Today";

import EmojiEvents from '@material-ui/icons/EmojiEvents';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import Stars from '@material-ui/icons/Stars';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import InfoArea from "components/InfoArea/InfoArea.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";

import Badge from 'components/Badge/Badge.js';

import subaProfile from "assets/img/suba_white_gray.png";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

    return (
        <div>
            <Header
                color="transparent"
                brand="Suhanó Bárányok"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/suba_regulation.JPG")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.profile}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div>
                                            <img src={subaProfile} alt="..." className={imageClasses} />
                                        </div>
                                        <div className={classes.name}>
                                            <h2>Suhanó Bárányok Alapszabályzata (részlet)</h2>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.description}>
                            <h3>II. Az egyesület célja, tevékenysége</h3>
                            <ol>
                                <li>
                                    <p align="justify">
                                        Az egyesület célja: Juhászkutya fajták és azok tartásának népszerűsítése; ideértve a
                                        kutyasporttal, mint szabadidős tevékenységgel kapcsolatos feladatok ellátását és támogatását,
                                        így különösen, de nem kizárólagosan a betanítást, versenyeztetést. Valamint az ezekhez
                                        kapcsolódó szervezési, oktatási területen végzett tevékenységeket. Hagyományőrzés, terelő
                                        hagyományok bemutatása mellett célunk az egyéb kutyás sportok támogatása képzésekkel és
                                        vizsgák rendezésével egyaránt. Úgy mint, terápiás kutyák, kereső és munkakutyák képzése,
                                        vizsgáztatása, alkalmazása. Az egyesület működésével, céljával és tevékenységével elismeri és
                                        elfogadja az FCI mint nemzetközi kinológiai társaság valamint az őt képviselő Magyar
                                        Ebtenyésztők Országos Egyesületeinek Szövetsége alapelveit és szabályzatait.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az egyesület tevékenysége: Egyesületünk működésével, céljával és tevékenységével elismeri és
                                        elfogadja, magára nézve kötelező érvényűnek tartja az FCI mint nemzetközi kinológiai
                                        társaság alapelveit és szabályzatait. A cél megvalósulása érdekében biztosítja tagjai részére a
                                        foglalkozásokon, eseményeken történő részvételt, a sportolási lehetőségeket. A juhászkutya
                                        fajták számára versenyek szervezése. Nemzetközi tornákon való részvétel. Nemzetközi
                                        juhászkutya szervezetekkel való együttműködés. A terelő sport népszerűsítése előadások, illetve
                                        bemutatók tartásával. A border collie, az ausztrál kelpie, tradicionális fajták - mint a puli, mudi,
                                        pumi- és más terelő juhászkutya fajták népszerűsítése. A juhászkutyák tenyésztését támogató
                                        vizsgák és versenysport biztosítása. Az általunk szervezni kívánt vizsgák és versenyek
                                        elsősorban de nem kizárólagosan a következők:
                                        <ul>
                                            <li>
                                                Terelő ágazatban: FCI NHAT, HWT, IHT-CS I-III és IHT-TS-I-III, valamint egyéb
                                                terelőversenyek.
                                            </li>
                                            <li>
                                                Egyéb ágazatok: terápiás kutyák vizsgáztatása, BH vizsga, Obedience ágazat vizsgái,
                                                szarvasgomba kereső vizsga, egyéb munkakutya versenyek és vizsgák.
                                            </li>
                                        </ul>
                                        Tanfolyamok, konzultációk szervezése. Más sportszervezetekkel kialakított kapcsolatok
                                        ápolása. Versenyzők támogatása, szabadidős- és sporttevékenységek szervezése és
                                        lebonyolítása. A tömegsport népszerűsítése a fiatalság körében. Az egyesület tevékenységi
                                        területe nem korlátozódik Magyarország területére, tevékenységét a határon túl is kifejtheti. Az
                                        egyesület bármely kutyasportot, egészséges életmódot elősegítő célt támogathat, akár tagjai,
                                        akár kívülálló személyek egyéni, csoportos, tömegsport, vagy versenysport, tehetséggondozás,
                                        utánpótlás-nevelés, szabadidősport jellegű tevékenységéhez nyújtott támogatással. A támogatás
                                        célcsoportját képezhetik elsődlegesen kutyatartók, családtagjaik, illetve gyermekek, fiatalok,
                                        sportoktatók, sportra és egészséges életmódra, állattartásra nevelő oktatási-, és
                                        képzőintézmények, vagy azok fenntartói, szerveződései, projekttársulásai, konzorciumai,
                                        függetlenül attól, hogy azok állami, önkormányzati, egyházi, alapítványi, vagy egyéb
                                        fenntartásban, kezelésben, vagy irányítás alatt vannak, ideértve bármely egyéb nem
                                        intézményesült formában működő, vagy létrejövő kutyatartást, sportot, egészséges életmódot
                                        támogató szerveződést. Az egyesület állattartást, kutyatartást, sportolási, egészséges életmódot
                                        támogató célt megvalósító – hazai, vagy uniós forrásból megvalósuló – projektekhez is
                                        támogatást nyújthat, ilyenekben akár pályázóként, akár együttműködő partnerként részt vehet. A
                                        magyar terelő hagyományok bemutatása, magyar terelő fajták népszerűsítése.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az egyesület az egyesületi cél megvalósításával közvetlenül összefüggő gazdasági tevékenység
                                        végzésére jogosult.
                                    </p>
                                </li>
                            </ol>
                            <h3>III. Az egyesület működésére vonatkozó általános szabályok</h3>
                            <ol>
                                <li>
                                    <p align="justify">
                                        Az egyesület az egyesülési jogról, a közhasznú jogállásról, valamint a civil szervezetek
                                        működéséről és támogatásáról szóló törvény (Ectv.) és a Polgári Törvénykönyvszabályai szerint
                                        működik.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az egyesület közvetlen politikai tevékenységet nem folytat, szervezete pártoktól független és
                                        azoknak anyagi támogatást nem nyújt, és tőlük ilyet nem fogad el.
                                    </p>
                                </li>
                            </ol>
                            <h3>IV. Tagdíj</h3>
                            <ol>
                                <li>
                                    <p align="justify">
                                        Az egyesület rendes tagjai vagyoni hozzájárulásként tagdíjat fizetnek. A tagdíj összegéről a
                                        közgyűlés egyszerű többségi határozattal dönt tárgy év február 28-ig. A tagok a tagdíj összegét
                                        egy összegben, minden év április 30-ig az egyesület házipénztárába vagy az egyesület
                                        bankszámlájára történő átutalás útján kötelesek megfizetni.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az újonnan belépő tag a tagsági jogviszonya keletkezésének évében a tagdíj teljes éves összegét
                                        köteles a tagsági jogviszony létesítésétől számított 8 napon belül az egyesület házipénztárába
                                        vagy az egyesület bankszámlájára történő átutalás útján teljesíteni.
                                    </p>
                                </li>
                            </ol>
                            <h3>V. A tagság</h3>
                            <p align="justify">
                                Az egyesület tagja lehet az a természetes személy és jogi személy, aki az egyesület
                                célkitűzéseivel egyetért és az alapszabályban foglalt rendelkezéseket elfogadja.
                            </p>
                            <h3>VI. A tagsági jogviszony keletkezése</h3>
                            <ol>
                                <li>
                                    <p align="justify">
                                        A tagsági jogviszony a belépési nyilatkozat elfogadásával kezdeményezhető, amelyben a belépni
                                        kívánó tag vállalja, hogy az egyesület céljait elfogadja, azzal egyetért, megvalósításában aktívan
                                        részt vesz, a tagsági díjat folyamatosan fizeti. A belépési nyilatkozatot az elnökséghez kell
                                        benyújtani, amely szerv a kérelem beérkezésétől számított 30 napon belül, egyszerű
                                        szótöbbséggel, nyílt szavazással határoz a tagfelvételről. Az Elnökség határozatát annak
                                        meghozatalát követő 8 napon belül, írásba foglaltan, igazolt módon kell megküldeni a tagfelvételt
                                        kérelmező számára. A tagfelvételi kérelem tárgyában hozott döntést az Elnökség nem köteles
                                        indokolni, a döntés ellen jogorvoslatnak helye nincs.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A tagsági jogviszony fajtái:<br />
                                        <u>Rendes tag (csak természetes személy):</u> Tag lehet minden természetes személy, aki kinyilvánítja
                                    belépési szándékát, ezt írásban megerősíti, elfogadja az egyesület alapszabályát, és felvételt nyer.
                                    A rendes tag az Egyesület teljes jogú tagja.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        <u>Tiszteletbeli tag (csak természetes személy):</u> Olyan természetes, jeles személy, aki erkölcsileg
                                        támogatja az egyesületet.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        <u>Pártoló tag:</u> Aki természetes, vagy jogi személyként anyagi, vagy egyéb lehetőségeivel támogatja a
                                        sportegyesületet (külön megállapodás alapján).
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A pártoló tagok jogállása a Ptk. 3:65.§ (2) bekezdése alá eső különleges jogállású tagság, mely a
                                        közgyűlés által meghatározott pártoló tagi tagdíjfizetési kötelezettséggel jár, azonban egyesületi
                                        közgyűlésen,szavazati, vagy részvételi joggal nem jár, egyéb az egyesület által biztosított
                                        szolgáltatás, juttatás igénybe vételére azonban jogosult.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A tiszteletbeli tagok jogállása a Ptk. 3:65.§ (2) bekezdése alá eső különleges jogállású tagság, mely
                                        tagdíjfizetési kötelezettséggel, valamint egyesületi közgyűlésen, szavazati, vagy részvételi joggal
                                        nem jár, egyéb az egyesület által biztosított szolgáltatás, juttatás igénybe vételére az elnökség
                                        döntése alapján jogosult.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az egyesület a tagjairól nyilvántartást vezet.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Ahol az Alapszabály tagot említ, az alatt a rendes tagot kell érteni.
                                    </p>
                                </li>
                            </ol>
                            <h3>VII. A tagsági jogviszony megszűnése</h3>
                            <ol>
                                <li>
                                    <p align="justify">
                                        A tagsági jogviszony megszűnik:
                                        <ol type="a">
                                            <li>
                                                A tag kilépésével.
                                            </li>
                                            <li>
                                                A tag halálával vagy jogutód nélküli megszűnésével.
                                            </li>
                                            <li>
                                                A tag kizárásával.
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A tagsági jogviszonyát a tag az egyesület elnökségéhez címzett írásbeli nyilatkozatával
                                        bármikor, indokolás nélkül megszüntetheti. A tagsági jogviszony a nyilatkozatának az
                                        elnökséghez történő megérkezése napján szűnik meg.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az elnökség nyílt szavazással, egyszerű szótöbbséggel kizárhatja az egyesület tagjai közül azt a
                                        tagot, aki jelen alapszabály rendelkezéseit vagy a közgyűlés határozatát súlyosan vagy
                                        ismételten sértő magatartást tanúsít. Kizárható a tag akkor is, ha a tagdíj megfizetésével 6
                                        hónapot meghaladó késedelme esik. A tagdíj megfizetésének elmulasztása miatt a tag csak akkor
                                        zárható ki, ha a legalább hat hónapos mulasztás elteltét követően az elnökség írásban –
                                        igazolható módon, póthatáridő tűzésével és a jogkövetkezményekre, azaz a kizárásra történő
                                        figyelmeztetéssel – felszólította a tagdíjhátralék teljesítésére, mely felszólítás a póthatáridőn
                                        belül is eredménytelen maradt.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A kizárási eljárást bármely tag vagy egyesületi szerv kezdeményezésére az elnökség folytatja le.
                                        A kizárási eljárásban a tagot az elnökség ülésére igazolható módon meg kell hívni, azzal a
                                        figyelmeztetéssel, hogy a szabályszerű meghívása ellenére történő távolmaradása az ülés
                                        megtartását és a határozathozatalt nem akadályozza. Az ülésen biztosítani kell számára a
                                        védekezési lehetőséget. Az ülésen a tag képviselővel is képviseltetheti magát. A tag kizárását
                                        kimondó határozatot írásba kell foglalni és indokolással kell ellátni; az indokolásnak
                                        tartalmaznia kell a kizárás alapjául szolgáló tényeket és bizonyítékokat, továbbá a jogorvoslati
                                        lehetőségről való tájékoztatást. Az elnökség a kizárásról szóló határozatot a tagkizárási eljárás
                                        megindulásától számított 30 napon belül meghozza és 8 napon belül igazolható módon közli az
                                        érintett taggal.
                                        A kizárt tag a kizárást kimondó elsőfokú elnökségi határozat ellen, a kézbesítéstől számított 15
                                        napon belül az egyesület közgyűléséhez fellebbezéssel élhet. A fellebbezés beérkezését követően
                                        az elnökségnek haladéktalanul, de legkésőbb 30 napon belüli időpontra össze kell hívnia a
                                        rendkívüli közgyűlést. A közgyűlés nyílt szavazással, egyszerű szótöbbséggel dönt. A közgyűlés
                                        határozatát annak meghozatalakor szóban kihirdeti és 8 napon belül írásban, igazolható módon
                                        is közli az érintett taggal.
                                    </p>
                                </li>
                            </ol>
                            <h3>VIII. A tagok jogai</h3>
                            <ol>
                                <li>
                                    <p align="justify">
                                        Az egyesület rendes tagja jogosult:
                                    <ol type="a">
                                            <li>
                                                az egyesület tevékenységében részt venni
                                        </li>
                                            <li>
                                                az egyesület szolgáltatásait igénybe venni
                                        </li>
                                            <li>
                                                a közgyűlésen részt venni, szavazati jogát gyakorolni, a közgyűlés rendjének megfelelően
                                                felszólalni, kérdéseket feltenni, javaslatokat és észrevételeket tenni
                                        </li>
                                            <li>
                                                az egyesület irataiba betekinteni
                                        </li>
                                            <li>
                                                arra, hogy az egyesület tisztségviselőjévé válasszák, amennyiben vele szemben
                                                jogszabályban meghatározott kizáró ok nem áll fenn.
                                        </li>
                                        </ol>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A rendes tag a közgyűlésen a szavazati jogát meghatalmazott képviselője útján is gyakorolhatja.
                                        A képviselő részére adott meghatalmazást teljes bizonyító erejű magánokirati formában írásba
                                        kell foglalni, és azt a közgyűlés levezető elnökének a közgyűlés kezdetén átadni.
                                        <br />
                                        A közgyűlésen valamennyi szavazásra jogosult rendes tag egyenlő szavazattal rendelkezik.
                                    </p>
                                </li>
                            </ol>
                            <h3>IX. A tagok kötelezettségei</h3>
                            <ol>
                                <li>
                                    <p align="justify">
                                        Az egyesület rendes tagja:
                                        <ol type="a">
                                            <li>
                                                Nem veszélyeztetheti az egyesület céljának megvalósítását és az egyesület tevékenységét.
                                            </li>
                                            <li>
                                                Köteles a tagdíjat annak esedékességéig megfizetni.
                                            </li>
                                            <li>
                                                Köteles az egyesület alapszabályának, a döntéshozó szervek határozatainak reá vonatkozó
                                                előírásait, rendelkezéseit betartani.
                                            </li>
                                            <li>
                                                Köteles a lakcímét annak megváltozását követő 8 napon belül az elnökséghez bejelenteni.
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                            </ol>

                            <h3>X. Az egyesület szervei</h3>
                            <ol>
                                <li>
                                    <p align="justify">
                                        Az egyesület szervei
                                        <ol type="a">
                                            <li>
                                                Közgyűlés
                                            </li>
                                            <li>
                                                Elnökség
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                            </ol>
                            <h3>A Közgyűlés</h3>
                            <ol start="2">
                                <li>
                                    <p align="justify">
                                        A közgyűlés az egyesület döntéshozó szerve.
                                        A közgyűlés ülései nyilvánosak, a nyilvánosság jogszabályban meghatározott esetekben
                                        korlátozható.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A közgyűlés hatáskörébe tartozik:
                                        <ol type="a">
                                            <li>
                                                az alapszabály módosítása
                                            </li>
                                            <li>
                                                az egyesület megszűnésének, egyesülésének és szétválásának elhatározása
                                            </li>
                                            <li>
                                                a vezető tisztségviselő megválasztása, visszahívása
                                            </li>
                                            <li>
                                                az éves költségvetés elfogadása, a tagdíj megállapítása
                                            </li>
                                            <li>
                                                az éves beszámoló - ezen belül az ügyvezető szervnek az egyesület vagyoni helyzetéről szóló
                                                jelentésének - elfogadása
                                            </li>
                                            <li>
                                                a vezető tisztségviselő feletti munkáltatói jogok gyakorlása, ha a vezető tisztségviselő az
                                                egyesülettel munkaviszonyban áll
                                            </li>
                                            <li>
                                                az olyan szerződés megkötésének jóváhagyása, amelyet az egyesület saját tagjával, vezető
                                                tisztségviselőjével vagy ezek hozzátartozójával köt
                                            </li>
                                            <li>
                                                a jelenlegi és korábbi egyesületi tagok és a vezető tisztségviselők kártérítési igények
                                                érvényesítéséről való döntés
                                            </li>
                                            <li>
                                                döntés mindazon kérdésben, amelyet jogszabály vagy alapszabály a hatáskörébe utal
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A közgyűlését évente legalább egyszer össze kell hívni, amelyen meg kell tárgyalnia éves
                                        pénzügyi tervét, illetve az előző éves pénzügyi terv teljesítéséről szóló, a számvitelről szóló
                                        törvény rendelkezései szerint készített beszámolót.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A közgyűlést az elnökség legalább 15 nappal az ülés időpontja előtt kiküldött meghívóval,
                                        elsődlegesen az egyesület székhelyére hívja össze írásban, igazolható módon. Írásbeli igazolható
                                        módon történő kézbesítésnek minősül: pl. ajánlott vagy tértivevényes küldeményként, továbbá a
                                        tagnak az elektronikus levelezési címére történő kézbesítés azzal, hogy a kézbesítés
                                        visszaigazolásra kerüljön (elektronikus tértivevény).
                                        Ha a közgyűlést nem szabályszerűen hívták össze, az ülést akkor lehet megtartani, ha az ülésen
                                        a részvételre jogosultak legalább háromnegyede jelen van, és egyhangúlag hozzájárul az ülés
                                        megtartásához.
                                        A közgyűlési meghívó tartalmazza az egyesület nevét, székhelyét, a közgyűlés helyét, idejét és a
                                        javasolt napirendi pontokat. A napirendi pontokat a meghívóban legalább olyan
                                        részletezettséggel kell rögzíteni, hogy a szavazásra jogosult tagok álláspontjukat kialakíthassák.
                                        A meghívónak tartalmaznia kell továbbá a közgyűlés határozatképtelensége esetére a
                                        megismételt közgyűlés helyszínét és időpontját, és az arra történő felhívást, hogy a megismételt
                                        közgyűlés az eredeti napirendi pontok tekintetében a megjelentek számára tekintet nélkül
                                        határozatképes lesz, ha azt az eredeti időpontot legalább három és legfeljebb tizenöt nappal
                                        követő időpontra hívják össze.
                                        A közgyűlési meghívót az egyesület székhelyén és honlapján nyilvánosságra kell hozni.
                                        A közgyűlési meghívó kézbesítésétől vagy közzétételétől számított 3 napon belül a tagok és az
                                        egyesület szervei az elnökségtől a napirend kiegészítését kérhetik, a kiegészítés indokolásával. A
                                        napirend kiegészítésének tárgyában az elnökség 2 napon belül dönt. Az elnökség a napirend
                                        kiegészítését elutasíthatja vagy a kérelemnek helyt adhat. Döntését, továbbá elfogadás esetén a
                                        kiegészített napirendi pontokat minden esetben annak meghozatalától számított legkésőbb 2
                                        napon belül igazolható módon közli a tagokkal.
                                        Ha az elnökség a napirend kiegészítése iránti kérelemről nem dönt, vagy a kérelmet elutasítja,
                                        úgy a közgyűlés a napirend elfogadásáról szóló határozat meghozatalát megelőzően külön dönt a
                                        napirend kiegészítésének tárgyában, azzal, hogy a szabályszerűen nem közölt napirenden
                                        szereplő kérdésben csak akkor hozható határozat, ha a részvételre jogosultak legalább
                                        háromnegyede jelen van és a napirenden nem szereplő kérdés megtárgyalásához
                                        egyhangúlag hozzájárul.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az elnökség köteles a közgyűlést haladéktalanul összehívni a szükséges intézkedések megtétele
                                        céljából, ha
                                        <ol type="a">
                                            <li>
                                                az egyesület vagyona az esedékes tartozásokat nem fedezi
                                            </li>
                                            <li>
                                                az egyesület előreláthatólag nem lesz képes a tartozásokat esedékességkor teljesíteni
                                            </li>
                                            <li>
                                                az egyesület céljainak elérése veszélybe került
                                            </li>
                                        </ol>
                                        Ezekben az esetekben az összehívott közgyűlésen a tagok kötelesek az összehívásra okot adó
                                        körülmény megszüntetése érdekében intézkedést tenni vagy az egyesület megszüntetéséről
                                        dönteni.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A közgyűlés határozatképes, ha azon a leadható szavazatok több mint felét képviselő szavazásra
                                        jogosult részt vesz. A határozatképességet minden határozathozatalnál vizsgálni kell.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A közgyűlés megnyitását követően elsődlegesen meg kell állapítani a határozatképességet,
                                        vagyis az aktuális taglétszámhoz képest a megjelent és szavazásra jogosult tagok számát. A
                                        közgyűlés a napirendi pontok tárgyalását megelőzően egyszerű szótöbbséggel, nyílt szavazással
                                        megválasztja a levezető elnök személyét, továbbá a jegyzőkönyvvezető és két jegyzőkönyv
                                        hitelesítő személyét, valamint szükség esetén a két fős szavazatszámláló bizottságot.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A közgyűlésen megjelent tagokról jelenléti ívet kell készíteni, amelyen fel kell tüntetni a tag,
                                        valamint – ha az alapszabály a képviselő útján történő részvételt lehetővé teszi – képviselője
                                        nevét és lakóhelyét vagy székhelyét, és – ha a tagokat nem azonos számú szavazat illeti meg – a
                                        tagot megillető szavazatok számát. A jelenléti ívet a közgyűlés levezető elnöke és a
                                        jegyzőkönyvvezető aláírásával hitelesíti.
                                        A közgyűlésről jegyzőkönyvet kell készíteni, amely tartalmazza
                                        <ol type="a">
                                            <li>
                                                az egyesület nevét és székhelyét
                                            </li>
                                            <li>
                                                a közgyűlés helyét és idejét
                                            </li>
                                            <li>
                                                a közgyűlés levezető elnökének, a jegyzőkönyvvezetőnek, a jegyzőkönyv hitelesítőjének a
                                                nevét
                                            </li>
                                            <li>
                                                a közgyűlésen lezajlott fontosabb eseményeket, az elhangzott indítványokat
                                            </li>
                                            <li>
                                                a határozati javaslatokat, a leadott szavazatok és ellenszavazatok, valamint a szavazástól
                                                tartózkodók számát
                                            </li>
                                        </ol>
                                        A jegyzőkönyvet a jegyzőkönyvvezető és a közgyűlés levezető elnöke írja alá, és két erre
                                        megválasztott, jelen lévő tag hitelesíti.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A tagok határozatukat a határozatképesség megállapításánál figyelembe vett szavazatok
                                        többségével hozzák meg. A határozat meghozatalakor nem szavazhat az,
                                        <ol type="a">
                                            <li>
                                                akit a határozat kötelezettség vagy felelősség alól mentesít vagy a jogi személy terhére
                                                másfajta előnyben részesít
                                            </li>
                                            <li>
                                                akivel a határozat szerint szerződést kell kötni
                                            </li>
                                            <li>
                                                aki ellen a határozat alapján pert kell indítani
                                            </li>
                                            <li>
                                                akinek olyan hozzátartozója érdekelt a döntésben, aki az egyesületnek nem tagja
                                            </li>
                                            <li>
                                                aki a döntésben érdekelt más szervezettel többségi befolyáson alapuló kapcsolatban áll
                                            </li>
                                            <li>
                                                aki egyébként személyesen érdekelt a döntésben
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A közgyűlés határozatát – az alapszabály vagy törvény eltérő rendelkezése hiányában –
                                        egyszerű szótöbbséggel, nyílt szavazással hozza. Az egyesület alapszabályának módosításához,
                                        az egyesület egyesüléséhez és szétválásához a közgyűlés háromnegyedes szótöbbséggel hozott
                                        határozata szükséges. Az egyesület céljának módosításához és az egyesület megszűnéséről szóló
                                        közgyűlési döntéshez a szavazati joggal rendelkező tagok háromnegyedes szótöbbséggel hozott
                                        határozata szükséges.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        A közgyűlési határozatokat a levezető elnök a közgyűlésen szóban kihirdeti és az érintett
                                        tag(okk)al a határozat meghozatalát követő 8 napon belül írásban, igazolható módon is közli a
                                        határozatnak az egyesület honlapján történő közzétételével egyidejűleg.
                                    </p>
                                </li>
                            </ol>
                            <h3>Elnökség</h3>
                            <ol start="13">
                                <li>
                                    <p align="justify">
                                        Az elnökség az egyesület 3 elnökségi tagból álló ügyvezető szerve, amely dönt mindazon
                                        kérdésekben, amelyet jogszabály vagy alapszabály nem utal a közgyűlés kizárólagos
                                        hatáskörébe.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az elnökség tagjait a közgyűlés választja 5 év határozott időtartamra.
                                        Megszűnik a vezető tisztségviselői megbízatás:
                                        <ol type="a">
                                            <li>
                                                a megbízás időtartamának lejártával
                                            </li>
                                            <li>
                                                visszahívással
                                            </li>
                                            <li>
                                                lemondással
                                            </li>
                                            <li>
                                                a vezető tisztségviselő halálával vagy jogutód nélküli megszűnésével
                                            </li>
                                            <li>
                                                a vezető tisztségviselő cselekvőképességének a tevékenysége ellátásához szükséges körben
                                                történő korlátozásával
                                            </li>
                                            <li>
                                                a vezető tisztségviselővel szembeni kizáró vagy összeférhetetlenségi ok bekövetkeztével
                                            </li>
                                        </ol>
                                        A vezető tisztségviselő megbízatásáról az egyesülethez címzett, az egyesület másik vezető
                                        tisztségviselőjéhez intézett nyilatkozattal bármikor lemondhat. Ha a jogi személy
                                        működőképessége ezt megkívánja, a lemondás az új vezető tisztségviselő kijelölésével vagy
                                        megválasztásával, ennek hiányában legkésőbb a bejelentéstől számított hatvanadik napon válik
                                        hatályossá.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Vezető tisztségviselő az a nagykorú személy lehet, akinek cselekvőképességét a tevékenysége
                                        ellátásához szükséges körben nem korlátozták. Ha a vezető tisztségviselő jogi személy, a jogi
                                        személy köteles kijelölni azt a természetes személyt, aki a vezető tisztségviselői feladatokat
                                        nevében ellátja.
                                        A vezető tisztségviselőkre vonatkozó szabályokat a kijelölt személyre is alkalmazni kell. A
                                        vezető tisztségviselő ügyvezetési feladatait személyesen köteles ellátni. Nem lehet vezető
                                        tisztségviselő az, akit bűncselekmény elkövetése miatt jogerősen szabadságvesztés büntetésre
                                        ítéltek, amíg a büntetett előélethez fűződő hátrányos következmények alól nem mentesült. Nem
                                        lehet vezető tisztségviselő, aki a közügyektől eltiltó ítélet hatálya alatt áll (Btk. 61.§ (2) bek. i)
                                        pont). Nem lehet vezető tisztségviselő az, akit e foglalkozástól jogerősen eltiltottak. Akit
                                        valamely foglalkozástól jogerős bírói ítélettel eltiltottak, az eltiltás hatálya alatt az ítéletben
                                        megjelölt tevékenységet folytató jogi személy vezető tisztségviselője nem lehet. Az eltiltást
                                        kimondó határozatban megszabott időtartamig nem lehet vezető tisztségviselő az, akit eltiltottak
                                        a vezető tisztségviselői tevékenységtől.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az egyesület vezető tisztségviselői: <br />
                                        Az egyesület elnöke:
                                        Tóth Szandra <br />
                                        Az elnökség tagjai: <br />
                                        Alelnökök: Patkó Istvánné, Belényi Beatrix Katalin <br />
                                        Az egyesület törvényes képviseletét az elnök önállóan vagy két másik elnökségi tag együttesen
                                        látja el.
                                        A képviseleti jog gyakorlásának terjedelme: általános.
                                        A képviseleti jog gyakorlásának módja: az elnök önállóan, az elnökségi tagok pedig valamely
                                        másik elnökségi taggal együttesen.
                                        Bankszámla feletti rendelkezésre az elnök önállóan, az elnökségi tagok pedig valamelyik másik
                                        elnökségi taggal együttesen jogosultak.
                                        Az alelnökök a közgyűlés, az elnökség által rájuk bízott feladatokat végzik, segítik az elnök
                                        munkáját, az elnök távolléte, akadályoztatása esetén helyettesítik az elnököt, jogszabály
                                        keretei között gyakorolják annak jogait, kötelességeit.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az elnökség hatáskörébe tartozik:
                                        <ol type="a">
                                            <li>
                                                az egyesület napi ügyeinek vitele, a hatáskörébe tartozó ügyekben a döntések meghozatala
                                            </li>
                                            <li>
                                                a beszámolók előkészítése és azoknak a közgyűlés elé terjesztése
                                            </li>
                                            <li>
                                                az éves költségvetés elkészítése és annak a közgyűlés elé terjesztése
                                            </li>
                                            <li>
                                                az egyesületi vagyon kezelése, a vagyon felhasználására és befektetésére vonatkozó, a
                                                közgyűlés hatáskörébe nem tartozó döntések meghozatala és végrehajtása
                                            </li>
                                            <li>
                                                a közgyűlés összehívása, a tagság és az egyesület szerveinek értesítése
                                            </li>
                                            <li>
                                                az elnökség által összehívott közgyűlés napirendi pontjainak meghatározása
                                            </li>
                                            <li>
                                                részvétel a közgyűlésen és válaszadás az egyesülettel kapcsolatos kérdésekre
                                            </li>
                                            <li>
                                                a tagság nyilvántartása
                                            </li>
                                            <li>
                                                az egyesület határozatainak, szervezeti okiratainak és egyéb könyveinek vezetése
                                            </li>
                                            <li>
                                                az egyesület működésével kapcsolatos iratok megőrzése
                                            </li>
                                            <li>
                                                az egyesületet érintő megszűnési ok fennállásának mindenkori vizsgálata és annak
                                                bekövetkezte esetén az e törvényben előírt intézkedések megtétele
                                            </li>
                                            <li>
                                                a tag felvételéről való döntés
                                            </li>
                                            <li>
                                                döntés mindazon kérdésben, amelyet jogszabály vagy alapszabály a hatáskörébe utal
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az elnökség ülései nyilvánosak, a nyilvánosság jogszabályban meghatározott esetekben
                                        korlátozható.
                                        Az elnökség üléseit szükség szerint, de évente legalább egy alkalommal tartja. Az elnökségi
                                        ülést az elnök legalább 15 nappal az ülés időpontja előtt kiküldött meghívóval, elsődlegesen az
                                        egyesület székhelyére hívja össze írásban, igazolható módon. Írásbeli igazolható módon történő
                                        kézbesítésnek minősül: pl. ajánlott vagy tértivevényes küldeményként, továbbá a tagnak az
                                        elektronikus levelezési címére történő kézbesítés azzal, hogy a kézbesítés visszaigazolásra
                                        kerüljön (elektronikus tértivevény).
                                        Az elnökségi ülésre szóló meghívó tartalmazza az egyesület nevét, székhelyét, az elnökségi ülés
                                        helyét, idejét és a javasolt napirendi pontokat. A napirendi pontokat a meghívóban legalább
                                        olyan részletezettséggel kell rögzíteni, hogy az elnökségi tagok álláspontjukat kialakíthassák.
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az elnökség határozatát egyszerű szótöbbséggel, nyílt szavazással hozza. Az elnökség
                                        határozatképes, ha ülésén a szavazati jogában nem korlátozott elnökségi tagok több mint a fele
                                        jelen van. Három elnökségi tag jelenléte esetén kizárólag egyhangúan hozható határozat.
                                        A határozat meghozatalakor nem szavazhat az,
                                        <ol type="a">
                                            <li>
                                                akit a határozat kötelezettség vagy felelősség alól mentesít vagy a jogi személy terhére
                                                másfajta előnyben részesít
                                            </li>
                                            <li>
                                                akivel a határozat szerint szerződést kell kötni
                                            </li>
                                            <li>
                                                aki ellen a határozat alapján pert kell indítani
                                            </li>
                                            <li>
                                                akinek olyan hozzátartozója érdekelt a döntésben, aki az egyesületnek nem tagja
                                            </li>
                                            <li>
                                                aki a döntésben érdekelt más szervezettel többségi befolyáson alapuló kapcsolatban áll
                                            </li>
                                            <li>
                                                aki egyébként személyesen érdekelt a döntésben
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify">
                                        Az elnökség határozatait az elnökségi ülésen szóban kihirdeti és az érintett tag(okk)al a
                                        határozat meghozatalát követő 8 napon belül írásban, igazolható módon is közli a
                                        határozatoknak az egyesület honlapján történő közzétételével egyidejűleg.
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
