import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
import Timeline from "@material-ui/icons/Timeline";

import Schedule from "@material-ui/icons/Schedule";
import List from "@material-ui/icons/List";
import ListAlt from "@material-ui/icons/ListAlt";
import LabelImportant from "@material-ui/icons/LabelImportant";
import Event from "@material-ui/icons/Event";
import EventAvailable from "@material-ui/icons/EventAvailable";
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Today from "@material-ui/icons/Today";

import EmojiEvents from '@material-ui/icons/EmojiEvents';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import Stars from '@material-ui/icons/Stars';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import InfoArea from "components/InfoArea/InfoArea.js";
import Warning from "components/Typography/Warning.js";
import Danger from "components/Typography/Danger.js";

import Badge from 'components/Badge/Badge.js';

import subaProfile from "assets/img/suba_white_gray.png";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ContinentalSponsors(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

    return (
        <div>
            <Header
                color="transparent"
                brand="Suhanó Bárányok"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/suba_regulation.JPG")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.profile}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div>
                                            <img src={subaProfile} alt="..." className={imageClasses} />
                                        </div>
                                        <div className={classes.name}>
                                            <h2>CONTINENTAL 2022 - SZPONZOROK / SPONSORS</h2>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.description}>
                            <h3>István Szigli Counselor of Székesfehérvár</h3>
                            <p>Patron - Main sponsor of the event</p>
                            <h3>Tornácos Ház Restaurant</h3>
                            <p>They started in 1996 as a family business restaurant and pansion at the lake Fertő,
                                today they are one of the most determinative cateing places of the area.
                                The chef of the restaurant is one of our founder members Lóránt Szabó. He will be
                                responsible for the high quality of our "gastronomical trip". </p>
                            <h3>BELCANDO – the official dog food sponsor of Continental Herding Trial</h3>
                            <p>We are grateful for the sponsorship of Kutyapest Dog School and Mrs. Nagy Gézáné Kati,
                                the distributor of Belcando products and the Budapest and Pest county regional
                                representative of Bewital Petfood. We appreciate their support with this high
                                quality dog food!
                                The appropriate nutrition is of key importance for the dogs’ every day life and  good
                                performance in sport activities: it is provided by BELCANDO professional dog foods,
                                consisting of high quality ingredients. The BELCANDO dry and wet dog foods do not contain
                                neither artificial components, preservatives, flavour enhancers, food colorants nor GMO
                                plants (not any wheat, soya been). They are easy to digest, made of  controlled
                                components and depending on the dog’s individual requirements porvide normal energy supply
                                or special diet starting from puppies up to senior dogs.
                                The Kutyapest Dog School is one of the oldest, traditional dog schools in Budapest.
                                They combine their practical knowledge accumulated during several decades with recent -
                                based on positive reinforcement- training methods. Let it be puppy socialization, basic
                                or intermediate obedience course, qualified dog trainers assist dogs and their owners to
                                develop their knowledge not only in obedience, but in guarding and tracking trainings as well.
                                The Kutyapest Dog School supports the herding trainings of several dogs of SUBA team members.
                                The shool leader Mr. Nagy Géza and his wife Mrs. Nagy Gézáné Kati are accustomed to herding
                                sport too, as they are owners of a border collie and a beauceron, both having herding working tests.</p>
                            <h3>Royal essences</h3>
                            <p>We are happy to introduce you one of our sponsors, the ROYAL ESSENCES brand
                                (https://royalessences.hu/), being one of the most important suppliers of diet
                                and nutrition supplements for pets in Hungary. The founders and the members of
                                the ROYAL ESSENCES have developed this product family for sporting and working
                                dogs based on their experience and knowledge accumulated for more than thirty years.
                                They are continuously improving the assortment to meet the requirements for active
                                dogs and for family pets as well. The nutrition supplements of ROYAL ESSENCES
                                consist of natural ingredients so they can be successfully used in different
                                nourishment methods. Whether it is a product for general condition improvement,
                                strengthening the immune system, protecting the joints or a special supplement
                                for sporting and working dogs, the wide product range of ROYAL ESSENCES is a
                                warranty for each dog to get the appropriate care required!</p>
                            <h3>WINERY BELECZ</h3>
                            <p>Our Sponsor, the winery „Belecz” is located on the Southern hillside of the
                                „Vértes” mountain, alongside the road Zámoly-Csákvár-Csókakő-Mór of the
                                nearby wine production region. It is situated 800 meters from the center
                                of Csákberény and 8 km from the city of Mór. The region has very good
                                natural conditions for grape cultivation. The southern orientation and
                                mild climate of the „Orond” region provide grapes of excellent quality,
                                good flavor and pleasant fragrance. The winery „Belecz” has been engaged
                                with grape cultivation and vine production since 1995. The winery is not
                                producing large quantities of vine but dedicated to produce high quality
                                of them. The organizers of the Continental Trial can prove it by their own
                                experiences as well</p>
                            <h3>Mókus Photo</h3>
                            <p>The Mókus Photo is the official photographer of the Continental Trial.
                                Robi has experience in making photographs of official, family and dog sport
                                events. Being a professional photographer he has made photos on several
                                greyhound racings, flyball, agility and coursing trainings and competitions.
                                As a new challenge, he is going to make shots of the specific and rare
                                atmosphere and moments of herding trials. During the trial he will make
                                photos on all of the three trial fields and they will be presented on this
                                website after finishing the trials.  The photos will be available with the
                                pricing as follows: in case ordering two photos the first one is free of
                                charge and starting from the second one EUR 7.- for each of them.</p>
                            <h3>Piret-mix</h3>
                            <p>Piret-mix is an efficient and popular product designated to fight against  fleas,
                                ticks and mosquitos and has been used in households and veterinary for more than
                                twenty years. It develops a long term effect – even during several weeks – by
                                utilizing its active natural active agent, the piretrin. Piretrin is a grist made
                                from the flowers of the Dalmatian pyrethrum. It is an expensive but natural and
                                effective insecticide; it can be used for both dog owners and their dogs to
                                safeguard them from parasites and insects. The Piret-mix can be sprayed on the pets’
                                fur directly and the clothes, shoes of their owners as well.
                                We appreciate Crhysatox Kft. for sponsoring our trial!</p>
                            <h3>Burgimmune</h3>
                            <p>The winner of the first TS Continental Trial will be supported by the Natural Pet Care System  with Burgimmune
                                The Burgimmune Premium immune support combination was developed by veterinarians with more dozen years of clinical experience.
                                The ingredients support the immune system against cancer and other diseases.
                                The effects by sporting dogs are:
                                <li>Stronger immune system</li>
                                <li>Helps concentration</li>
                                <li>Support regeneration after injury</li>
                                <li>Preparest the body for higher physical, mental performance</li>
                                <li>Maximises the vitality, freshness, endurance, and focus</li>
                                Veterinarian preparation for dogs and cats
                                Only natural ingredients!
                                Order the product on our website, and mark our team as recommender: ZEN Agility – so you will support also an animal shelter company!
                                In case of questions: zenagility@gmail.com
                                https://www.naturalpetcaresystem.com/termekeinkgazdiknak
                                For prevention, and in case of health problems:  N.P.C.S. - Burgimmune tapasztalatok & gyógyulások </p>
                            <h3>EBORIGINAL - Egészségbolt nemcsak kutyáknak</h3>
                            <p>NUTROLIN ® <br />A kisállat táplálékkiegészítő olajok új generációja
                                Magas dózisú, válogatott omega zsírsavak szinergista tápanyagokkal kiegészítve, a tökéletes támogatás érdekében. Válassz kedvenced igényének megfelelően - kölyökkortól időskorig!
                                <br />
                                ORTOMAT - mozgásfejlesztő játékok
                                <br />
                                Szuper kiegészítői az otthoni tréningezésnek - legyen szó fiatal, idős, egészséges vagy rehabilitáció alatt álló kutyáról!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
