import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Camera from "@material-ui/icons/Camera";
// import Palette from "@material-ui/icons/Palette";
// import Favorite from "@material-ui/icons/Favorite";
// import Timeline from "@material-ui/icons/Timeline";

// import Schedule from "@material-ui/icons/Schedule";
// import List from "@material-ui/icons/List";
import ListAlt from "@material-ui/icons/ListAlt";
// import LabelImportant from "@material-ui/icons/LabelImportant";
// import Event from "@material-ui/icons/Event";
// import EventAvailable from "@material-ui/icons/EventAvailable";
// import InsertInvitation from "@material-ui/icons/InsertInvitation";
// import CalendarToday from "@material-ui/icons/CalendarToday";
// import Today from "@material-ui/icons/Today";

import EmojiEvents from '@material-ui/icons/EmojiEvents';
import PetsIcon from '@material-ui/icons/Pets';
import SchoolIcon from '@material-ui/icons/School';
import Stars from '@material-ui/icons/Stars';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import InfoArea from "components/InfoArea/InfoArea.js";
import Warning from "components/Typography/Warning.js";

import Badge from 'components/Badge/Badge.js';

import subaProfile from "assets/img/suba_white_gray.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { Pets, HowToReg, DoorbellIcon, OutdoorGrill, House, Sports, Timer } from "@material-ui/icons";

// import Festival from '@mui/icons-material/Festival';

const useStyles = makeStyles(styles);

export default function ContinentalProgram(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

    return (
        <div>
            <Header
                color="transparent"
                brand="Suhanó Bárányok"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/barik.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.profile}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div>
                                            <img src={subaProfile} alt="..." className={imageClasses} />
                                        </div>
                                        <div className={classes.name}>
                                            <h2>TS CONTINENTAL TRIAL 2022 Programok</h2>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className={classes.description}>
                            <p align="justify">
                                Információk a versenyről:
                                <br />Az esemény időtartama: Április 14 Csütörtök 13.30 - Április 17 Vasárnap 20.00
                                <br />Helyszín: 8000 Székesfehérvár, Nyúl dűlő 14, Hungary
                                <br />Pályák: A, B, C
                            </p>
                            <p align="justify">
                                Informations about the competition:
                                <br />Duration of the event: 14 April Thursday 13.30 - 17 April Sunday 20.00
                                <br />Location: 8000 Székesfehérvár, Nyúl dűlő 14, Hungary
                                <br />Fields: A, B, C
                            </p>
                        </div>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                                <NavPills
                                    alignCenter
                                    color="primary"
                                    tabs={[
                                        {
                                            tabButton: "Csütörtök / Thursday",
                                            tabIcon: ListAlt,
                                            tabContent: (
                                                <GridContainer justify="center">
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <InfoArea
                                                            title="Regisztráció - Registration"
                                                            description="14:00 - 16:00"
                                                            information="A teljesítmény könyvetek szükséges. Your performance book is required."
                                                            icon={HowToReg}
                                                            iconColor="info"
                                                        />
                                                        <InfoArea
                                                            title="Hatósági állatorvosi ellenőrzés - Official vetenary exam"
                                                            description="14:00 - 16:00"
                                                            information="A kutyátok oltási igazolásai szükségesek. Your vaccination certificates of your dog is required."
                                                            icon={Pets}
                                                            iconColor="info"
                                                        />
                                                        <InfoArea
                                                            title="Megnyitó / Opening ceremony"
                                                            description="16:30 - 18:00"
                                                            information="Ünnepélyes megnyitó beszédekkel és sorszámhúzással. Opening ceremony with speeches and starting number draw."
                                                            icon={House}
                                                            iconColor="success"
                                                        />
                                                        <InfoArea
                                                            title="Gála vacsora - Dinner"
                                                            description="18:00"
                                                            icon={OutdoorGrill}
                                                            iconColor="success"
                                                        />
                                                        {/* <a href="#" target="_blank">MENU</a> */}
                                                    </GridItem>
                                                </GridContainer>
                                            )
                                        },
                                        {
                                            tabButton: "Péntek / Friday",
                                            tabIcon: EmojiEvents,
                                            tabContent: (
                                                <GridContainer justify="center">
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <InfoArea
                                                            title="TS 3 CACT-R - 'A' Pályabejárás / Course Walk"
                                                            description="6:15 - 7:00 'A' pálya - 'A' field start no: 1 - 11"
                                                            information="Bíró / Judge: Sinikka Kumpusalmi"
                                                            icon={Timer}
                                                            iconColor="warning"
                                                        />
                                                        <InfoArea
                                                            title="TS 3 CACT-R - 'B' Pályabejárás / Course Walk"
                                                            description="6:15 - 7:00 'B' pálya - 'B' field start no: 12 - 22"
                                                            information="Bíró / Judge: Petra Szarvas"
                                                            icon={Timer}
                                                            iconColor="warning"
                                                        />
                                                        <InfoArea
                                                            title="TS 1 - 'C' Pályabejárás / Course Walk"
                                                            description="7:00 - 7:30 'C' pálya - 'C' field"
                                                            information="Bíró / Judge: Gabriella Patkó"
                                                            icon={Timer}
                                                            iconColor="success"
                                                        />
                                                        <InfoArea
                                                            title="TS 3 CACT-R - Selejtező / Qualifier"
                                                            description="8:00 'A' pálya - 'A' field start no: 1 - 11"
                                                            information="Bíró / Judge: Sinikka Kumpusalmi"
                                                            icon={EmojiEvents}
                                                            iconColor="warning"
                                                        />
                                                        <a href="/continental-participants">Rajtsorrend / Starting order</a>
                                                        <InfoArea
                                                            title="TS 3 CACT-R - Selejtező / Qualifier"
                                                            description="8:00 'B' pálya - 'B' field start no: 12 - 22"
                                                            information="Judge: Petra Szarvas"
                                                            icon={EmojiEvents}
                                                            iconColor="warning"
                                                        />
                                                        <a href="/continental-participants">Rajtsorrend / Starting order</a>
                                                        {/* <Warning>További információ később!</Warning> */}
                                                        <InfoArea
                                                            title="TS 1 - 1. kör / 1st round"
                                                            description="8:00 'C' pálya - 'C' field"
                                                            information="Judge: Gabriella Patkó"
                                                            icon={EmojiEvents}
                                                            iconColor="success"
                                                        />
                                                        <a href="/continental-participants">Rajtsorrend / Starting order</a>
                                                        <InfoArea
                                                            title="TS 2 - 'A' Pályabejárás / Course Walk"
                                                            description="14:30 - 15:00 'A' pálya - 'A' field start no: 1 - 5"
                                                            information="Bíró / Judge: Sinikka Kumpusalmi"
                                                            icon={Timer}
                                                            iconColor="info"
                                                        />
                                                        <InfoArea
                                                            title="TS 2 - 'B' Pályabejárás / Course Walk"
                                                            description="14:30 - 15:00 'B' pálya - 'B' field start no: 6 - 10"
                                                            information="Bíró / Judge: Petra Szarvas"
                                                            icon={Timer}
                                                            iconColor="info"
                                                        />
                                                        <a href="/continental-participants">Rajtsorrend / Starting order</a>
                                                        <InfoArea
                                                            title="TS 2 - 1. kör / 1st round"
                                                            description="15:00 'A' pálya - 'A' field start no: 1 - 5"
                                                            information="Judge: Sinikka Kumpusalmi"
                                                            icon={EmojiEvents}
                                                            iconColor="info"
                                                        />
                                                        <InfoArea
                                                            title="TS 2 - 1. kör / 1st round"
                                                            description="15:00 'B' pálya - 'B' field start no: 6 - 10"
                                                            information="Judge: Petra Szarvas"
                                                            icon={EmojiEvents}
                                                            iconColor="info"
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                            )
                                        },
                                        {
                                            tabButton: "Szombat / Saturday",
                                            tabIcon: SchoolIcon,
                                            tabContent: (
                                                <GridContainer justify="center">
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <InfoArea
                                                            title="TS 2 - 'A' Pályabejárás / Course Walk"
                                                            description="6:30 - 7:00 'A' pálya - 'A' field start no: 6 - 10"
                                                            information="Bíró / Judge: Sinikka Kumpusalmi"
                                                            icon={Timer}
                                                            iconColor="info"
                                                        />
                                                        <InfoArea
                                                            title="TS 2 - 'B' Pályabejárás / Course Walk"
                                                            description="6:30 - 7:00 'B' pálya - 'B' field start no: 1 - 5"
                                                            information="Bíró / Judge: Petra Szarvas"
                                                            icon={Timer}
                                                            iconColor="info"
                                                        />
                                                        <a href="/continental-participants">Rajtsorrend / Starting order</a>
                                                        <InfoArea
                                                            title="TS 1 - 'C' Pályabejárás / Course Walk"
                                                            description="7:00 - 7:30 'C' pálya - 'C' field"
                                                            information="Bíró / Judge: Gabriella Patkó"
                                                            icon={Timer}
                                                            iconColor="success"
                                                        />
                                                        <InfoArea
                                                            title="TS 2 - 2. kör / 2nd round"
                                                            description="8:00 'A' pálya - 'A' field start no: 10 - 6"
                                                            information="Judge: Sinikka Kumpusalmi"
                                                            icon={EmojiEvents}
                                                            iconColor="info"
                                                        />
                                                        <InfoArea
                                                            title="TS 2 - 2. kör / 2nd round"
                                                            description="8:00 'B' pálya - 'B' field start no: 5 - 1"
                                                            information="Judge: Petra Szarvas"
                                                            icon={EmojiEvents}
                                                            iconColor="info"
                                                        />
                                                        <a href="/continental-participants">Rajtsorrend / Starting order</a>
                                                        <InfoArea
                                                            title="TS 1 - 2. kör / 2nd round"
                                                            description="8:00 'C' pálya - 'C' field"
                                                            information="Judge: Gabriella Patkó"
                                                            icon={EmojiEvents}
                                                            iconColor="success"
                                                        />
                                                        <a href="/continental-participants">Rajtsorrend / Starting order</a>
                                                        <InfoArea
                                                            title="TS 3 CACT-R - 'A' Pályabejárás / Course Walk"
                                                            description="10:30 - 11:15 'A' pálya - 'A' field start no: 12 - 22"
                                                            information="Bíró / Judge: Sinikka Kumpusalmi"
                                                            icon={Timer}
                                                            iconColor="warning"
                                                        />
                                                        <InfoArea
                                                            title="TS 3 CACT-R - 'B' Pályabejárás / Course Walk"
                                                            description="10:30 - 11:15 'B' pálya - 'B' field start no: 1 - 11"
                                                            information="Bíró / Judge: Petra Szarvas"
                                                            icon={Timer}
                                                            iconColor="warning"
                                                        />
                                                        <InfoArea
                                                            title="TS 3 CACT-R - Selejtező / Qualifier"
                                                            description="11:30 'A' pálya - 'A' field start no: 22 - 12"
                                                            information="Bíró / Judge: Sinikka Kumpusalmi"
                                                            icon={EmojiEvents}
                                                            iconColor="warning"
                                                        />
                                                        <a href="/continental-participants">Rajtsorrend / Starting order</a>
                                                        <InfoArea
                                                            title="TS 3 CACT-R - Selejtező / Qualifier"
                                                            description="11:30 'B' pálya - 'B' field start no: 11 - 1"
                                                            information="Judge: Petra Szarvas"
                                                            icon={EmojiEvents}
                                                            iconColor="warning"
                                                        />
                                                        <a href="/continental-participants">Rajtsorrend / Starting order</a>
                                                        {/* <Warning>További információ később!</Warning> */}
                                                        <InfoArea
                                                            title="TS 1 & TS 2 Eredményhirdetés / Prize giving ceremony"
                                                            description="17:00"
                                                            information="Központi terület / Main area"
                                                            icon={Stars}
                                                            iconColor="rose"
                                                        />
                                                        {/* <a href="/continental-results">Eredmények / Results</a> */}
                                                        <hr></hr>
                                                    </GridItem>
                                                </GridContainer>
                                            )
                                        },
                                        {
                                            tabButton: "Vasárnap / Sunday",
                                            tabIcon: SchoolIcon,
                                            tabContent: (
                                                <GridContainer justify="center">
                                                    <GridItem xs={12} sm={12} md={8}>
                                                        <h3>Döntő futam a legjobb 12 TS 3 kutyának. Final race for the best 12 TS 3 dogs</h3>
                                                        <InfoArea
                                                            title="TS 3 CACIT-R - Pályabejárás / Course Walk"
                                                            description="8:00 - 8:45 'A' pálya - 'A' field"
                                                            information="Bírók / Judges: Sinikka Kumpusalmi, Gabriella Patkó, Petra Szarvas"
                                                            icon={Timer}
                                                            iconColor="warning"
                                                        />
                                                        <InfoArea
                                                            title="TS 3 CACIT-R - Döntő / Final"
                                                            description="9:00 'A' pálya - 'A' field"
                                                            information="Bírók / Judges: Sinikka Kumpusalmi, Gabriella Patkó, Petra Szarvas"
                                                            icon={EmojiEvents}
                                                            iconColor="warning"
                                                        />
                                                        <a href="/continental-participants">Rajtsorrend / Starting order</a>
                                                        <InfoArea
                                                            title="TS 3 CACIT-R Eredményhirdetés / Prize giving ceremony"
                                                            description="16:00"
                                                            information="Központi terület / Main area"
                                                            icon={Stars}
                                                            iconColor="rose"
                                                        />
                                                        {/* <Warning>További információ később!</Warning> */}
                                                    </GridItem>
                                                </GridContainer>
                                            )
                                        }
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}
